import * as Api from "@/api";
import FormInputTypes from "@/components/Profile/FormInputTypes";
import { AvForm } from "availity-reactstrap-validation";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import PageContainer from "../../components/Common/PageContainer";
import useStore from "../../zustandStore";
import EmailPreview from "../EmailTemplate/components/EmailPreview";
import { SENDING_STATUS_OPTIONS } from "./EmailList";

const BREADCRUMB_ITEMS = [
  { title: "Email History", link: "#" },
  { title: "Detail", link: "#" },
];

const EmailDetails = () => {
  const navigate = useNavigate();
  const { token } = useStore();
  const location = useLocation();

  const [email, setEmail] = useState<Api.Response.Email>({
    histIdx: 0,
    targetEmail: "",
    status: 0,
    failMessage: "",
    title: "",
    content: "",
    regName: "",
    regId: "",
    regIdx: 0,
    regDt: "",
    updDt: "",
  });

  const { histIdx } = useParams();

  useEffect(() => {
    const getEmailByIdx = async () => {
      try {
        if (token) {
          Api.environment.setAccessToken(token);
        }
        if (!histIdx) return;
        const response = await Api.Emailing.get(parseInt(histIdx));
        const existingEmail = response.data;

        if (existingEmail) {
          setEmail(existingEmail);
        }
      } catch (error) {
        console.error("Error fetching Email from API", error);
      }
    };
    getEmailByIdx();
  }, []);

  const handleNavigation = () => {
    navigate(`/email-history${location.search}`);
  };

  return (
    <>
      <PageContainer breadcrumbItems={BREADCRUMB_ITEMS} title="Email Template">
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <h4 className="card-title">이메일 전송상세입니다.</h4>
                <AvForm className="mt-4">
                  <Row>
                    <FormInputTypes
                      id="title"
                      label="이메일제목"
                      type="text"
                      value={email.title || ""}
                      fullWidthLabel={false}
                      isDisabled
                    />
                  </Row>
                  <Row>
                    <FormInputTypes
                      id="targetEmail"
                      label="전송대상"
                      type="text"
                      value={email.targetEmail || ""}
                      fullWidthLabel={false}
                      isDisabled
                    />
                  </Row>
                  <Row>
                    <FormInputTypes
                      id="status"
                      label="전송상태"
                      value={
                        email?.status
                          ? SENDING_STATUS_OPTIONS?.find(
                              (option) => option?.value === email?.status,
                            )?.label || ""
                          : ""
                      }
                      type="text"
                      fullWidthLabel={false}
                      isDisabled
                    />
                  </Row>

                  <Row>
                    <FormInputTypes
                      id="failMessage"
                      label="실패사유"
                      type="text"
                      value={email.failMessage || ""}
                      fullWidthLabel={false}
                      isDisabled
                    />
                  </Row>

                  <Row>
                    <FormInputTypes
                      id="regName"
                      label="전송자"
                      type="text"
                      value={email.regName || ""}
                      fullWidthLabel={false}
                      isDisabled
                    />
                  </Row>
                  <Row>
                    <FormInputTypes
                      id="regDt"
                      label="요청일자"
                      type="text"
                      value={email.regDt || ""}
                      fullWidthLabel={false}
                      isDisabled
                    />
                  </Row>
                  <Row>
                    <FormInputTypes
                      id="updDt"
                      label="갱신일자"
                      type="text"
                      value={email.updDt || ""}
                      fullWidthLabel={false}
                      isDisabled
                    />
                  </Row>
                  <Row>
                    <Col xl="2" md="2">
                      <Label className="col-md-2 col-form-label w-100">
                        전송내용
                      </Label>
                    </Col>
                    <Col xl="10" md="10">
                      <EmailPreview initialTemplate={email.content || ""} />
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end mt-2">
                    <Button
                      type="button"
                      color="primary"
                      className="me-2"
                      onClick={handleNavigation}
                    >
                      목록보기
                    </Button>
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </PageContainer>
    </>
  );
};

export default EmailDetails;
