import React, { useCallback, useEffect, useState } from "react";
import PageContainer from "../../components/Common/PageContainer";
import ControlPanel from "../../components/Common/ControlPanel";
import { registrationOptions, showOptions } from "../Utility/Options";
import CustomTableContainer from "../../components/Common/CustomTableContainer";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import * as Api from "@/api";
import useStore from "../../zustandStore";
import usePrintingProfileStore from "../../zustandStore/printingProfilesStore";
import { isProd } from "../../utils/isProd";
import { toast, ToastContainer } from "react-toastify";
import ModalTypes from "../../components/Common/ModalTypes.tsx";
import resultTable, { GetColumns } from "./Utils/index.js";
import ConfirmModalTypes from "../../components/Common/ConfirmModalTypes";
import { DEFAULT_ERROR_MESSAGE } from "../../helpers/constants";
import { useLocalizedMessage } from "../../helpers/hooks";

const BREADCRUMB_ITEMS = [
  { title: "Printing Profile", link: "#" },
  { title: "List", link: "#" },
];

const PrintingProfile = () => {
  const { token } = useStore();
  const { profiles, setProfiles, loading, setLoading, setError } =
    usePrintingProfileStore();
  const navigate = useNavigate();
  const location = useLocation();
  const getLocalizedMessage = useLocalizedMessage();

  const queryParams = queryString.parse(location.search);
  const [customPageSize, setCustomPageSize] = useState(
    parseInt(queryParams.pageSize) || 10,
  );
  const [currentPage, setCurrentPage] = useState(
    parseInt(queryParams.page) || 0,
  );
  const [totalPage, setTotalPage] = useState(0);
  const [totalProfiles, setTotalProfiles] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(queryParams.keyword || "");
  const [dateRange, setDateRange] = useState([
    queryParams.startDt || "",
    queryParams.endDt || "",
  ]);
  const [applicationTypes, setApplicationTypes] = useState([]);
  const [selectedAppType, setSelectedAppType] = useState(
    queryParams.appType || 0,
  );
  const [selectedRegType, setSelectedRegType] = useState(
    queryParams.regType || registrationOptions[0].value,
  );
  const [selectedShow, setSelectedShow] = useState(
    queryParams.show || showOptions[0].value,
  );
  const [clearTrigger, setClearTrigger] = useState(false);
  const [selectedProfileIds, setSelectedProfileIds] = useState([]);
  const [copyResult, setCopyResult] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState("");

  const handleRowClick = (item) => {
    navigate(`/printing-profile/${item.printingIdx}${window.location.search}`);
  };

  const handleAddProfile = () => {
    navigate(`/printing-profile/new${window.location.search}`);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: dateRange[0],
      endDt: dateRange[1],
      appType: selectedAppType,
      regType: selectedRegType,
      show: selectedShow,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    searchKeyword,
    dateRange,
    selectedAppType,
    selectedRegType,
    selectedShow,
    location.pathname,
    navigate,
  ]);

  const fetchProfiles = async () => {
    setLoading(true);
    setError(null);

    try {
      if (token) {
        Api.environment.setAccessToken(token);
      }
      const requestData = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: searchKeyword,
        displayFlag: selectedShow,
        defaultFlag: selectedRegType,
        applicationsType: selectedAppType,
        startDt: dateRange[0] ? format(dateRange[0], "yyyy-MM-dd") : "",
        endDt: dateRange[1] ? format(dateRange[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.PrintingProfile.getList(requestData);
      if (response.code !== 0) {
        throw new Error(response.message);
      }

      setTotalPage(response.paging.totalPage);
      setTotalProfiles(response.paging.totalRecord);
      setProfiles(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getApplicationTypes = useCallback(async () => {
    const response = await Api.Common.getApplicationList();
    const applications = response.data;
    const updatedApplications = [
      {
        name: "전체",
        imgPath: "",
        applicationsIdx: 0,
      },
      ...applications,
    ];

    setApplicationTypes(updatedApplications);
  }, []);

  useEffect(() => {
    if (token) {
      Api.environment.setAccessToken(token);
      getApplicationTypes();
    }
  }, [getApplicationTypes, token]);

  useEffect(() => {
    fetchProfiles();
  }, [
    currentPage,
    customPageSize,
    selectedAppType,
    selectedRegType,
    selectedShow,
    dateRange[1],
    clearTrigger,
  ]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchProfiles();
  };

  const handleAppTypeSearch = (newAppType) => {
    setSelectedAppType(newAppType);
    setCurrentPage(0);
  };

  const handleRegTypeSearch = (newRegType) => {
    setSelectedRegType(newRegType);
    setCurrentPage(0);
  };

  const handleShowSearch = (newShow) => {
    setSelectedShow(newShow);
    setCurrentPage(0);
  };

  const handleDateSearch = (newDateRange) => {
    setDateRange(newDateRange);
    setCurrentPage(0);
  };

  const clearFilters = () => {
    setSearchKeyword("");
    setDateRange("");
    setSelectedRegType(registrationOptions[0].value);
    setSelectedShow(showOptions[0].value);
    setCurrentPage(0);
    setSelectedAppType(0);
    setClearTrigger((prev) => !prev);
  };

  const handleCheckboxChange = useCallback((printingIdx) => {
    setSelectedProfileIds((prev) =>
      prev.includes(printingIdx)
        ? prev.filter((id) => id !== printingIdx)
        : [...prev, printingIdx],
    );
  }, []);

  const handleCheckAll = useCallback(
    (e) => {
      if (e.target.checked) {
        setSelectedProfileIds(profiles.map((profile) => profile.printingIdx));
      } else {
        setSelectedProfileIds([]);
      }
    },
    [profiles],
  );

  const openModal = () => {
    if (!selectedProfileIds.length) {
      toast.error("먼저 프린팅 프로파일을 선택해주세요", {
        autoClose: 3000,
      });
      return;
    }

    setAction("copy");
    setIsOpen(true);
  };

  const handleCopyForMigration = async () => {
    setLoading(true);
    try {
      const requestData = {
        idx: selectedProfileIds.join(","),
      };
      const response = await Api.PrintingProfile.copyForMigration(requestData);
      setCopyResult(response.data.result);
      setIsModalOpen(true);
      setSelectedProfileIds([]);
    } catch (error) {
      console.error("Error: copy Printing Profile", error);
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
      setIsOpen(false);
    }
  };

  const columns = GetColumns(
    selectedProfileIds,
    handleCheckboxChange,
    handleRowClick,
  );

  return (
    <PageContainer breadcrumbItems={BREADCRUMB_ITEMS} title="Printing Profile">
      <ControlPanel
        placeholder="프로파일명, 프린터명, 소재명, 제조사명, 등록자명 등으로 검색해주세요."
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        clearFilters={clearFilters}
        onSearch={handleSearch}
        dateRange={dateRange}
        setDateRange={handleDateSearch}
        options0={applicationTypes}
        selectedOption0={selectedAppType}
        setSelectedOption0={handleAppTypeSearch}
        options1={registrationOptions}
        options2={showOptions}
        selectedOption1={selectedRegType}
        setSelectedOption1={handleRegTypeSearch}
        selectedOption2={selectedShow}
        setSelectedOption2={handleShowSearch}
        selectTitle0="구분"
        selectTitle1="기본프로파일여부"
        selectTitle2="표시여부"
      />
      <CustomTableContainer
        btnTitle="프로파일 등록"
        btnTitle1={isProd ? "스테이징 복사" : "프로덕션 복사"}
        btnIcon1="mdi mdi-content-copy me-2"
        handleClickBtn1={openModal}
        sortByIdx="printingIdx"
        handleAddItem={handleAddProfile}
        handleRowClick={handleRowClick}
        columns={columns || []}
        data={profiles || []}
        isPagination={true}
        customPageSize={customPageSize}
        totalPage={totalPage}
        totalRecord={totalProfiles}
        setCustomPageSize={setCustomPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isLoading={loading}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        selectedIds={selectedProfileIds}
        handleCheckboxChange={handleCheckboxChange}
        handleCheckAll={handleCheckAll}
        showCheckboxColumn
      />
      <ConfirmModalTypes
        isLoading={loading}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        action={action}
        handleCopy={handleCopyForMigration}
      />
      <ModalTypes
        title="복사 결과"
        isLoading={loading}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        handleSave={(prev) => setIsModalOpen(!prev)}
        isSingleBtn
      >
        {resultTable(copyResult)}
      </ModalTypes>
      <ToastContainer />
    </PageContainer>
  );
};

export default PrintingProfile;
