import React from "react";
import { Label } from "reactstrap";
import { Input } from "reactstrap";

type RadioInputProps = {
  onHandleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: number;
  id: string;
  label: string;
  checked: boolean;
};
const RadioInput = ({
  onHandleChange,
  value,
  id,
  label,
  checked,
}: RadioInputProps) => {
  return (
    <span className="form-check">
      <Input
        type="radio"
        id={id}
        name="targetType"
        className="form-check-input"
        value={value}
        checked={checked}
        onChange={onHandleChange}
      />
      <Label className="form-check-label" for={id}>
        {label}
      </Label>
    </span>
  );
};

export default RadioInput;
