import * as Api from "@/api";
import ConfirmModalTypes from "@/components/Common/ConfirmModalTypes";
import FormButtonsTypes from "@/components/Common/FormButtonsTypes";
import FormInputTypes from "@/components/Profile/FormInputTypes";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from "@/helpers/constants";
import { useLocalizedMessage } from "@/helpers/hooks";
import useEmailTemplateStore from "@/zustandStore/emailTemplateStore";

import { AvForm } from "availity-reactstrap-validation";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import PageContainer from "../../components/Common/PageContainer";
import useStore from "../../zustandStore";
import CustomCkEditor from "./components/CustomCkEditor";

type EmailTemplateFormProps = {
  isDetailView: boolean;
};

type EmailTemplateProps = Api.Request.AddEmailTemplate &
  Partial<Pick<Api.Response.EmailTemplate, "tempIdx">>;

const EmailTemplateForm = ({ isDetailView }: EmailTemplateFormProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { tempIdx } = useParams();
  const { token } = useStore();
  const { loading, setLoading } = useEmailTemplateStore();
  const getLocalizedMessage = useLocalizedMessage();

  const [emailTemplate, setEmailTemplate] = useState<EmailTemplateProps>({
    title: "",
    content: "",
    contentEn: "",
  });
  const [isEditorFocused, setIsEditorFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState("");

  const breadcrumbItems = [
    { title: "Email Template", link: "#" },
    { title: `${isDetailView ? "Edit" : "Add"}`, link: "#" },
  ];

  const getEmailTemplateByIdx = useCallback(async () => {
    try {
      if (token) {
        Api.environment.setAccessToken(token);
      }
      if (!tempIdx) return;
      const response = await Api.EmailTemplate.get(parseInt(tempIdx));
      const existingEmailTemplate = response.data;
      if (existingEmailTemplate) {
        setEmailTemplate(existingEmailTemplate);
      }
    } catch (error) {
      console.error("Error fetching Email Template from API", error);
    }
  }, [token, tempIdx]);

  useEffect(() => {
    if (isDetailView) {
      getEmailTemplateByIdx();
    }
  }, [getEmailTemplateByIdx, isDetailView]);

  const handleSaveEmailTemplate = async () => {
    if (
      !emailTemplate.title ||
      !emailTemplate.content ||
      !emailTemplate.contentEn
    ) {
      toast.error("템플릿명과 내용을 입력해주세요", {
        autoClose: 3000,
      });
      setIsOpen(false);
      return;
    }

    setLoading(true);
    try {
      const requestData = {
        title: emailTemplate.title,
        content: emailTemplate.content,
        contentEn: emailTemplate.contentEn,
      };
      let response;

      if (isDetailView) {
        if (!emailTemplate.tempIdx) return;

        response = await Api.EmailTemplate.update({
          ...requestData,
          tempIdx: emailTemplate.tempIdx,
        });
      } else {
        response = await Api.EmailTemplate.add(requestData);
      }
      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        navigate("/email-template");
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
      setIsOpen(false);
    }
  };

  const handleDeleteEmailTemplate = async () => {
    if (!emailTemplate.tempIdx) return;
    setLoading(true);
    try {
      const response = await Api.EmailTemplate.delete(emailTemplate.tempIdx);
      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        navigate("/email-template");
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(`/email-template${location.search}`);
  };

  const openModal = (actionType: string) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleChange = (e: {
    target: {
      name: string;
      value: string;
    };
  }) => {
    const { name, value } = e.target;
    const newValue = value;

    setEmailTemplate((prevTemp) => ({ ...prevTemp, [name]: newValue }));
  };

  return (
    <>
      <PageContainer breadcrumbItems={breadcrumbItems} title="Email Template">
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <h4 className="card-title">이메일 템플릿명을 입력해주세요</h4>
                <AvForm className="mt-4">
                  <Row>
                    <FormInputTypes
                      id="title"
                      label="템플릿명"
                      placeholder="템플릿명을 입력해주세요"
                      type="text"
                      value={emailTemplate.title || ""}
                      onChange={handleChange}
                      initialErrorMessage="템플릿명을 입력해주세요"
                      validate={{
                        required: {
                          value: true,
                        },
                      }}
                      fullWidthLabel={false}
                      isRequired
                    />
                  </Row>
                  <Row className="mb-3">
                    <Label
                      htmlFor="content"
                      className="col-md-2 col-form-label"
                    >
                      내용(한글) (<span className="text-danger">*</span>)
                    </Label>
                    <Col md={10}>
                      <CustomCkEditor
                        id="content"
                        data={emailTemplate.content || ""}
                        isFocused={isEditorFocused}
                        handleFocus={() => setIsEditorFocused(true)}
                        handleBlur={() => setIsEditorFocused(false)}
                        onChange={(content: string) =>
                          setEmailTemplate((prev) => ({ ...prev, content }))
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label
                      htmlFor="contentEn"
                      className="col-md-2 col-form-label"
                    >
                      내용(영문) (<span className="text-danger">*</span>)
                    </Label>
                    <Col md={10}>
                      <CustomCkEditor
                        id="contentEn"
                        data={emailTemplate.contentEn || ""}
                        isFocused={isEditorFocused}
                        handleFocus={() => setIsEditorFocused(true)}
                        handleBlur={() => setIsEditorFocused(false)}
                        onChange={(contentEn: string) =>
                          setEmailTemplate((prev) => ({ ...prev, contentEn }))
                        }
                      />
                    </Col>
                  </Row>
                  <FormButtonsTypes
                    isDetailView={isDetailView}
                    openModal={openModal}
                    handleCancel={handleCancel}
                  />
                  <ConfirmModalTypes
                    isLoading={loading}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    action={action}
                    handleSave={handleSaveEmailTemplate}
                    handleDelete={handleDeleteEmailTemplate}
                  />
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </PageContainer>
    </>
  );
};

export default EmailTemplateForm;
