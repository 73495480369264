import "ckeditor5/ckeditor5.css";

import { CKEditor } from "@ckeditor/ckeditor5-react";

import {
  Alignment,
  Base64UploadAdapter,
  BlockQuote,
  Bold,
  ClassicEditor,
  Code,
  CodeBlock,
  Essentials,
  GeneralHtmlSupport,
  Heading,
  HtmlEmbed,
  Image,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Mention,
  Paragraph,
  SourceEditing,
  Table,
  TableToolbar,
  Undo,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import classNames from "classnames";
import { useEffect, useRef } from "react";

type CustomCkEditorProps = {
  id: string;
  isFocused: boolean;
  data: string;
  handleFocus: () => void;
  handleBlur: () => void;
  onChange: (data: string) => void;
};

const CustomCkEditor = ({
  id,
  data,
  isFocused,
  handleFocus,
  handleBlur,
  onChange,
}: CustomCkEditorProps) => {
  const editorRef = useRef<ClassicEditor | null>(null);

  useEffect(() => {
    const checkSourceMode = () => {
      if (editorRef.current) {
        const sourceEditing = editorRef.current.plugins.get("SourceEditing");
        if (sourceEditing && sourceEditing.isSourceEditingMode) {
          const sourceElement = document.querySelector(
            ".ck-source-editing-area textarea",
          );
          if (sourceElement) {
            const content = (sourceElement as HTMLTextAreaElement).value;
            onChange(content);
          }
        }
      }
    };

    const interval = setInterval(checkSourceMode, 500);

    return () => clearInterval(interval);
  }, [onChange]);

  const handleEditorChange = (_event: unknown, editor: ClassicEditor) => {
    const content = editor.getData();
    onChange(content);
  };

  const handleEditorReady = (editor: ClassicEditor) => {
    editorRef.current = editor;
  };

  return (
    <div
      className={classNames(
        "ck ck-reset ck-editor ck-rounded-corners mt-0 mb-3",
        { "editor-wrapper-focused": isFocused },
      )}
    >
      <CKEditor
        id={id}
        editor={ClassicEditor}
        data={data}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleEditorChange}
        onReady={handleEditorReady}
        config={{
          toolbar: {
            items: [
              "undo",
              "redo",
              "sourceEditing",
              "|",
              "heading",
              "bold",
              "italic",
              "bulletedList",
              "numberedList",
              "insertTable",
              "blockQuote",
              "codeBlock",
              "alignment",
              "outdent",
              "indent",
              "link",
              "|",
              "uploadImage",
              "imageStyle:alignLeft",
              "imageStyle:alignCenter",
              "imageStyle:alignRight",
              "resizeImage",
              "mediaEmbed",
            ],
          },
          plugins: [
            Bold,
            Essentials,
            Italic,
            Heading,
            Link,
            List,
            Table,
            TableToolbar,
            BlockQuote,
            Image,
            ImageUpload,
            ImageResize,
            ImageStyle,
            ImageToolbar,
            Code,
            Mention,
            Paragraph,
            Undo,
            SourceEditing,
            GeneralHtmlSupport,
            Base64UploadAdapter,
            HtmlEmbed,
            Alignment,
            Indent,
            IndentBlock,
            MediaEmbed,
            CodeBlock,
          ],
          htmlSupport: {
            allow: [
              {
                name: /.*/,
                attributes: true,
                classes: true,
                styles: true,
              },
            ],
          },
          image: {
            toolbar: [
              "imageTextAlternative", // Adds the 'alt text' option
              "imageStyle:inline",
              "imageStyle:block",
              "imageStyle:side",
            ],
          },
        }}
      />
    </div>
  );
};

export default CustomCkEditor;
