import React, { useState } from "react";
import { Card, CardBody, Col, InputGroup, Label, Row } from "reactstrap";

import Flatpickr from "react-flatpickr";

import { useRef } from "react";
import {
  ArrowDownIcon,
  CustomInputGroupText,
  CustomSelect,
  CustomSelectContainer,
  SearchInput,
} from "../../pages/Users/styled";
import "./customStyles.css";
import { CustomTooltip } from "./styled";

type SearchInputChangeEvent = {
  target: {
    value: React.SetStateAction<string | (string | null)[]>;
  };
};

type ControlPanelProps<T, U> = {
  children?: React.ReactNode;
  placeholder: string;
  searchKeyword: string | (string | null)[];
  setSearchKeyword: React.Dispatch<
    React.SetStateAction<string | (string | null)[]>
  >;
  clearFilters: () => void;
  onSearch: () => void;
  dateRange: [string, string];
  setDateRange: (value: [string, string]) => void;
  options0?: Array<{ applicationsIdx: number; name: string }>;
  selectedOption0?: number;
  setSelectedOption0?: React.Dispatch<React.SetStateAction<number>>;
  selectTitle0?: string;
  options1?: Array<{ label: string; value: U }>;
  option1Type?: string;
  selectedOption1?: U;
  setSelectedOption1?: (value: U) => void;
  options2?: Array<{ label: string; value: T }>;
  option2Type?: string;
  selectedOption2?: T;
  setSelectedOption2?: (value: T) => void;
  selectTitle1?: string;
  selectTitle2?: string;
  isSingleSelect?: boolean;
};

const ControlPanelTypes = <
  T extends string | number | readonly string[] | undefined,
  U extends string | number | readonly string[] | undefined,
>({
  children,
  placeholder,
  searchKeyword,
  setSearchKeyword,
  clearFilters,
  onSearch,
  dateRange,
  setDateRange,
  options0,
  selectedOption0,
  setSelectedOption0,
  selectTitle0,
  options1,
  option1Type,
  options2,
  option2Type,
  selectedOption1,
  selectedOption2,
  setSelectedOption1,
  setSelectedOption2,
  selectTitle1,
  selectTitle2,
  isSingleSelect,
}: ControlPanelProps<T, U>) => {
  const flatpickrRef = useRef<null | Flatpickr>(null);

  const [isHovered, setIsHovered] = useState<{
    filter: boolean;
    search: boolean;
    calendar: boolean;
  }>({
    filter: false,
    search: false,
    calendar: false,
  });

  const handleIconClick = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.open();
    }
  };

  const handleKeyPress = (e: { key: string }) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  const handleDateChange = (selectedDates: Date[]) => {
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date) =>
        date.toISOString().slice(0, 10),
      );
      setDateRange([formattedDates[0] || "", formattedDates[1] || ""]);
    }
  };

  const handleSelect1Change = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    let newValue: U;

    if (option1Type === "number") {
      newValue = Number(value) as U;
    } else {
      newValue = value as U;
    }

    if (setSelectedOption1) {
      setSelectedOption1(newValue);
    }
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    let newValue: T;

    if (option2Type === "number") {
      newValue = Number(value) as T;
    } else {
      newValue = value as T;
    }

    if (setSelectedOption2) {
      setSelectedOption2(newValue);
    }
  };

  return (
    <Col xs={12} style={{ padding: "0 6px" }}>
      <Card>
        <CardBody>
          <Row>
            <Col sm={12} className="form-control-sm">
              <InputGroup>
                <SearchInput
                  value={searchKeyword}
                  type="text"
                  id="example-text-input"
                  placeholder={placeholder}
                  onChange={(e: SearchInputChangeEvent) =>
                    setSearchKeyword(e.target.value)
                  }
                  onKeyPress={handleKeyPress}
                />
                <CustomInputGroupText
                  onClick={clearFilters}
                  className="custom-color cursor-pointer"
                  id="FilterTooltip"
                >
                  <i className="ri-refresh-line"></i>
                </CustomInputGroupText>

                <CustomTooltip
                  placement="top"
                  isOpen={isHovered.filter}
                  target="FilterTooltip"
                  toggle={() =>
                    setIsHovered((prev) => ({ ...prev, filter: !prev.filter }))
                  }
                >
                  필터 초기화
                </CustomTooltip>

                <CustomInputGroupText
                  className="custom-color cursor-pointer"
                  onClick={onSearch}
                  id="SearchTooltip"
                >
                  <i className="ri-search-line"></i>
                </CustomInputGroupText>

                <CustomTooltip
                  placement="top"
                  isOpen={isHovered.search}
                  target="SearchTooltip"
                  toggle={() =>
                    setIsHovered((prev) => ({ ...prev, search: !prev.search }))
                  }
                >
                  검색
                </CustomTooltip>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="form-control-sm">
              <InputGroup className="cursor-pointer">
                <Flatpickr
                  ref={flatpickrRef}
                  className="form-control d-block form-control-sm"
                  placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
                  options={{
                    mode: "range",
                    dateFormat: "Y-m-d",
                  }}
                  value={dateRange}
                  onChange={handleDateChange}
                />
                <CustomInputGroupText
                  className="custom-color"
                  onClick={handleIconClick}
                  id="CalendarTooltip"
                >
                  <i className="ri-calendar-event-fill cursor-pointer"></i>
                </CustomInputGroupText>
                <CustomTooltip
                  placement="top"
                  isOpen={isHovered.calendar}
                  target="CalendarTooltip"
                  toggle={() =>
                    setIsHovered((prev) => ({
                      ...prev,
                      calendar: !prev.calendar,
                    }))
                  }
                >
                  기간 선택
                </CustomTooltip>
              </InputGroup>
            </Col>
            {options0 ? (
              <>
                <Col sm={4} className="form-select-sm">
                  <div>
                    <Label className="form-label">{selectTitle0}</Label>
                    <CustomSelectContainer>
                      <CustomSelect
                        className="form-control"
                        value={selectedOption0}
                        onChange={(e) => {
                          const selectedValue = parseInt(e.target.value, 10);
                          setSelectedOption0?.(selectedValue);
                        }}
                      >
                        {options0.map((option, idx) => (
                          <option key={idx} value={option.applicationsIdx}>
                            {option.name}
                          </option>
                        ))}
                      </CustomSelect>
                      <ArrowDownIcon className="ri-arrow-down-s-line"></ArrowDownIcon>
                    </CustomSelectContainer>
                  </div>
                </Col>
                <Col sm={4} className="form-select-sm">
                  <div>
                    <Label className="form-label">{selectTitle1}</Label>
                    <CustomSelectContainer>
                      <CustomSelect
                        className="form-control"
                        value={selectedOption1}
                        onChange={handleSelect1Change}
                      >
                        {options1?.map((option, idx) => (
                          <option key={idx} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </CustomSelect>
                      <ArrowDownIcon className="ri-arrow-down-s-line"></ArrowDownIcon>
                    </CustomSelectContainer>
                  </div>
                </Col>
                <Col sm={4} className="form-select-sm">
                  <div>
                    <Label className="form-label ">{selectTitle2}</Label>
                    <CustomSelectContainer>
                      <CustomSelect
                        className="form-control "
                        value={selectedOption2}
                        onChange={handleSelectChange}
                      >
                        {options2?.map((option, idx) => (
                          <option key={idx} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </CustomSelect>
                      <ArrowDownIcon className="ri-arrow-down-s-line"></ArrowDownIcon>
                    </CustomSelectContainer>
                  </div>
                </Col>
              </>
            ) : (
              <>
                {selectedOption1 !== undefined && (
                  <Col sm={6} className="form-select-sm">
                    <div>
                      <Label className="form-label">{selectTitle1}</Label>
                      <CustomSelectContainer>
                        <CustomSelect
                          className="form-control"
                          value={selectedOption1}
                          onChange={handleSelect1Change}
                        >
                          {options1?.map((option, idx) => (
                            <option key={idx} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </CustomSelect>
                        <ArrowDownIcon className="ri-arrow-down-s-line"></ArrowDownIcon>
                      </CustomSelectContainer>
                    </div>
                  </Col>
                )}
                {selectedOption2 !== undefined && (
                  <Col sm={isSingleSelect ? 12 : 6} className="form-select-sm">
                    <div>
                      <Label className="form-label ">{selectTitle2}</Label>
                      <CustomSelectContainer>
                        <CustomSelect
                          className="form-control "
                          value={selectedOption2}
                          onChange={handleSelectChange}
                        >
                          {options2?.map((option, idx) => (
                            <option key={idx} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </CustomSelect>
                        <ArrowDownIcon className="ri-arrow-down-s-line"></ArrowDownIcon>
                      </CustomSelectContainer>
                    </div>
                  </Col>
                )}
              </>
            )}
          </Row>
          <Row>{children}</Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ControlPanelTypes;
