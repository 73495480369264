import * as ApiBase from "@metamorp/api-base";
import { UpdateUser, GetUserList, AddUser, User, UserItem } from "./types";

const updateUser = (data: UpdateUser) => {
  type Response = ApiBase.Types.ApiStandardResBase<null>;
  return ApiBase.request<Response, UpdateUser>({
    method: "PUT",
    path: "/api/v1/user/update",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getUserList = (data: GetUserList) => {
  type Response = ApiBase.Types.ApiListResBase<UserItem>;
  return ApiBase.request<Response, GetUserList>({
    method: "POST",
    path: "/api/v1/user/list",
    data: data,
  });
};

const addUser = (data: AddUser) => {
  type Response = ApiBase.Types.ApiStandardResBase<null>;
  return ApiBase.request<Response, AddUser>({
    method: "POST",
    path: "/api/v1/user/add",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getUser = (idx: number) => {
  type Response = ApiBase.Types.ApiStandardResBase<User>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/user/${idx}`,
  });
};

const deleteUser = (idx: number) => {
  return ApiBase.request<ApiBase.Types.ApiStandardResBase<null>>({
    method: "DELETE",
    path: `/api/v1/user/${idx}`,
  });
};

export default {
  /**
   * `PUT /api/v1/user/update`
   *
   * 사용자 정보를 수정합니다.
   *
   * Update an user data.
   */
  update: updateUser,
  /**
   * `POST /api/v1/user/list`
   *
   * 사용자 목록을 조회합니다.
   *
   * Retrieve a list of users.
   */
  getList: getUserList,
  /**
   * `POST /api/v1/user/add`
   *
   * 사용자 정보를 추가합니다.
   *
   * Add an user data.
   */
  add: addUser,
  /**
   * `GET /api/v1/user/:idx`
   *
   * 사용자 정보를 조회합니다.
   *
   * Retrieve an user data.
   */
  get: getUser,
  /**
   * `DELETE /api/v1/user/:idx`
   *
   * 사용자 정보를 삭제합니다.
   *
   * Delete an user data.
   */
  delete: deleteUser,
};
