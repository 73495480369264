import * as Api from "@/api";
import { useEffect, useRef, useState } from "react";
import { Spinner } from "reactstrap";
import CardHeader from "./components/CardHeader";
import { calculateHight } from "./utils";

type UsageByFunctionProps = {
  data: Api.Response.UsedFunction;
  selectedDays: number;
  onDaysSelection: (days: number) => void;
  isLoading: boolean;
};

const UsageByFunction = ({
  data,
  selectedDays,
  onDaysSelection,
  isLoading,
}: UsageByFunctionProps) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!chartRef.current || isLoading) return;

    const chartOptions = {
      series: [
        {
          name: "AI Preparation",
          data: data.cnt0List,
        },
        {
          name: "슬라이싱",
          data: data.cnt1List,
        },
        {
          name: "자동정렬",
          data: data.cnt2List,
        },
        {
          name: "메쉬채우기",
          data: data.cnt3List,
        },
        {
          name: "비우기",
          data: data.cnt4List,
        },
        {
          name: "오프셋",
          data: data.cnt5List,
        },
        {
          name: "복제",
          data: data.cnt6List,
        },
        {
          name: "리메쉬",
          data: data.cnt7List,
        },
        {
          name: "단순화",
          data: data.cnt8List,
        },
        {
          name: "유니온",
          data: data.cnt9List,
        },
        {
          name: "서브트랙트",
          data: data.cnt10List,
        },
        {
          name: "인터섹터",
          data: data.cnt11List,
        },
        {
          name: "서포트",
          data: data.cnt12List,
        },
      ],
      chart: {
        type: "bar",
        height: `${calculateHight(selectedDays)}px`,
        stacked: true,
        stackType: "100%",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
        dropShadow: {
          enabled: true,
          top: 1,
          left: 1,
          blur: 1,
          opacity: 0.1,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      colors: [
        "#5c67f7",
        "#e354d4",
        "#9e5cf7",
        "#fe5454",
        "#0ca3e7",
        "#f7a35c",
        "#54d48e",
        "#f5a3f7",
        "#478024",
        "#67a3f7",
        "#f7d354",
        "#54a3f7",
        "#0c9081",
      ],
      grid: {
        borderColor: "#f2f5f7",
      },
      xaxis: {
        categories: data.dateList,
        labels: {
          show: true,
          style: {
            colors: "#8c9097",
            fontSize: "11px",
            fontWeight: 600,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            colors: "#8c9097",
            fontSize: "11px",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-label",
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            return val + "K";
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: true,
        position: "top",
        fontSize: "10px",
        markers: {
          width: 7,
          height: 7,
        },
      },
    };

    // This is "actionChart"
    const chart = new ApexCharts(chartRef.current, chartOptions);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data, isLoading]);

  return (
    <div className="col-12">
      <div className="card custom-card">
        <CardHeader
          title="기능별 사용빈도"
          tooltipId="funcUsageInfo"
          tooltipContent="선택기간동안 기능별 사용빈도
              <br />
              입니다."
          isHovered={isHovered}
          setIsHovered={() => setIsHovered((prev) => !prev)}
          selectedDays={selectedDays}
          onDaysSelection={onDaysSelection}
          dayOptions={[14, 30, 60]}
        />
        <div className="card-body pt-0">
          <div style={{ minHeight: "25rem" }}>
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center position-absolute top-0 start-0 end-0 bottom-0">
                <Spinner className="me-2" color="secondary" />
              </div>
            ) : null}
            <div
              ref={chartRef}
              style={{ width: "100%", height: "25rem" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsageByFunction;
