import * as Api from "@/api";
import { format } from "date-fns";
import queryString from "query-string";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CellProps, Column } from "react-table";
import { toast, ToastContainer } from "react-toastify";
import { Input } from "reactstrap";
import ControlPanelTypes from "../../components/Common/ControlPanelTypes";
import CustomTableContainerTypes, {
  type CustomColumn,
} from "../../components/Common/CustomTableContainerTypes";
import ModalTypes from "../../components/Common/ModalTypes";
import PageContainer from "../../components/Common/PageContainer";
import useQueryParams from "../../helpers/hooks";
import useStore from "../../zustandStore";
import useAppEquipmentStore, {
  AppEquipmentProps,
} from "../../zustandStore/appEquipmentStore";
import { memberStatusOptions } from "../Users";
import { ClickableCell } from "../Utility/CustomCells";
import ResultTable from "./Utils";
import SlicingRequestModal from "./Utils/SlicingRequestModal";

const INPUT_FILE_PLACEHOLDER = "선택된 파일 없음";

export type AppEquipmentColumn = Column<AppEquipmentProps> & {
  disableFilters?: boolean;
  filterable?: boolean;
  accessor: keyof AppEquipmentProps | "checked";
  Cell?: (props: CellProps<AppEquipmentProps>) => JSX.Element | string;
};

export type ClientProps = {
  clientIdx: number;
  clientName: string;
};

export type OptionProps = {
  value: number;
  label: string;
};

type CheckboxCellProps = {
  appIdx: number;
  selectedIds: number[];
  isEnable?: boolean;
  onCheckboxChange: (row: number) => void;
};

export const CheckboxCell = React.memo(
  ({ appIdx, isEnable, selectedIds, onCheckboxChange }: CheckboxCellProps) => {
    if (!isEnable) return "-";

    return (
      <Input
        type="checkbox"
        className="form-check-input input-mini"
        id={`row-checkbox-${appIdx}`}
        checked={selectedIds.includes(appIdx)}
        onChange={() => onCheckboxChange(appIdx)}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.appIdx === nextProps.appIdx &&
      prevProps.selectedIds === nextProps.selectedIds
    );
  },
);

const BREADCRUMB_ITEMS = [
  { title: "App Management", link: "#" },
  { title: "List", link: "#" },
];

const AppEquipment = () => {
  const { token } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams: queryString.ParsedQuery<string> = queryString.parse(
    location.search,
  );
  const { appEquipments, setAppEquipments, loading, setLoading, setError } =
    useAppEquipmentStore();

  const [printerOptions, setPrinterOptions] = useState<OptionProps[]>([]);
  const [selectedAppIds, setSelectedAppIds] = useState<number[]>([]);
  const [selectedPrinter, setSelectedPrinter] = useState<number | null>(null);

  const { pageSize, page, keyword, dateRange, parseQueryParam } =
    useQueryParams();
  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalCustomers, setTotalAppEquipments] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const [selectedDates, setSelectedDates] =
    useState<[string, string]>(dateRange);
  const [selectedStatus, setSelectedStatus] = useState<number>(
    parseQueryParam(queryParams.status) ?? memberStatusOptions[0].value,
  );
  const [clients, setClients] = useState<OptionProps[]>([]);
  const [selectedClient, setSelectedClient] = useState<number>(
    parseQueryParam(queryParams.client) ?? -1,
  );
  const [clearTrigger, setClearTrigger] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [fileName, setFileName] = useState(INPUT_FILE_PLACEHOLDER);
  const [files, setFiles] = useState<FileList | null>(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [slicingResult, setSlicingResult] = useState<
    Api.Response.SlicingResult[]
  >([]);

  const handleRowClick = useCallback(
    (item: { appIdx: number }) => {
      navigate(`/app-equipment/${item.appIdx}${window.location.search}`);
    },
    [navigate],
  );

  const handleAddAppEquipment = () => {
    navigate(`/app-equipment/new${window.location.search}`);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: selectedDates[0],
      endDt: selectedDates[1],
      status: selectedStatus,
      client: selectedClient,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    selectedDates,
    searchKeyword,
    selectedClient,
    selectedStatus,
    location.pathname,
    navigate,
  ]);

  const getClients = useCallback(async () => {
    const response = await Api.Common.getClientList();
    const clients: ClientProps[] = response?.data;
    const updatedClients: ClientProps[] = [
      {
        clientName: "전체",
        clientIdx: -1,
      },
      ...clients,
    ];
    const options: OptionProps[] = updatedClients.map((client) => ({
      label: client.clientName,
      value: client.clientIdx,
    }));
    setClients(options);
  }, []);

  useEffect(() => {
    if (token) {
      Api.environment.setAccessToken(token);
      getClients();
    }
  }, [getClients, token]);

  const fetchAppEquipments = async () => {
    setLoading(true);
    setError(null);

    try {
      if (token) {
        Api.environment.setAccessToken(token);
      }

      const requestData = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: typeof searchKeyword === "string" ? searchKeyword : "",
        stateFlag: selectedStatus,
        clientIdx: selectedClient,
        startDt: selectedDates[0] ? format(selectedDates[0], "yyyy-MM-dd") : "",
        endDt: selectedDates[1] ? format(selectedDates[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.App.getList(requestData);

      if (response.code !== 0) {
        throw new Error(response.message.ko);
      }
      setTotalPage(response.paging.totalPage);
      setTotalAppEquipments(response.paging.totalRecord);
      setAppEquipments(response.data);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
        console.error("Error fetching customers:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAppEquipments();
  }, [
    token,
    currentPage,
    customPageSize,
    selectedStatus,
    selectedClient,
    selectedDates[1],
    clearTrigger,
  ]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchAppEquipments();
  };

  const handleStatusSearch = (newStatus: number) => {
    setSelectedStatus(newStatus);
    setCurrentPage(0);
  };

  const handleDateSearch = (newDateRange: [string, string]) => {
    setSelectedDates(newDateRange);
    setCurrentPage(0);
  };

  const handleClientSearch = (newClient: number) => {
    setSelectedClient(newClient);
    setCurrentPage(0);
  };

  const clearFilters = () => {
    setSearchKeyword("");
    setSelectedDates(["", ""]);
    setSelectedStatus(memberStatusOptions[0].value);
    setSelectedClient(-1);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const getPrinters = async () => {
    setLoading(true);
    setError(null);
    try {
      if (token) {
        Api.environment.setAccessToken(token);
      }

      const requestData = {
        pageSize: 999999,
        nowPage: 1,
        pageGroup: 10,
        keyword: "",
        displayFlag: 1,
        defaultFlag: -1,
        startDt: "",
        endDt: "",
      };
      const response = await Api.Printer.getList(requestData);
      setPrinterOptions(
        response.data.map((item) => ({
          value: item.printerIdx,
          label: item.printerName,
        })),
      );
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        console.error("Unexpected error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const openInputModal = () => {
    if (selectedAppIds.length === 0) {
      toast.error("먼저 앱(장비)를 선택해주세요", {
        autoClose: 3000,
      });
      return;
    } else {
      getPrinters();
      setFileName(INPUT_FILE_PLACEHOLDER);
      setSelectedPrinter(-1);
      setIsOpen(true);
    }
  };

  const handleSlicingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;

    if (files && files.length > 0) {
      if (files.length === 1) {
        setFileName(files[0].name);
        setFiles(files);
      } else {
        const fileNames = Array.from(files)
          .map((file) => file?.name)
          .join(", ");
        setFileName(fileNames);
        setFiles(files);
      }
    } else if (name === "printerIdx") {
      setSelectedPrinter(parseInt(value, 10));
    } else {
      setFileName(INPUT_FILE_PLACEHOLDER);
      setFiles(null);
    }
  };

  const handleSlicing = async () => {
    if (
      !selectedPrinter ||
      !files ||
      !(files instanceof FileList) ||
      files.length === 0
    ) {
      toast.error("Please select printer and STL file(s)");
      return;
    }
    setLoading(true);

    try {
      const filesArray = Array.from(files);
      if (!selectedPrinter) return;

      const requestData = {
        appIdxs: selectedAppIds.join(","),
        printerIdx: selectedPrinter,
        files: filesArray,
      };
      const response = await Api.App.requestSlicing(requestData);
      if (response?.data?.result) {
        setSlicingResult(response.data.result);
      }

      setSuccessMessage(response?.message.ko);
      setIsSuccessModalOpen(true);

      setIsOpen(false);
      setSelectedAppIds([]);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message, {
          autoClose: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = useCallback((appIdx: number) => {
    setSelectedAppIds((prev) =>
      prev.includes(appIdx)
        ? prev.filter((id) => id !== appIdx)
        : [...prev, appIdx],
    );
  }, []);

  const handleCheckAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setSelectedAppIds(
          appEquipments
            .filter((app) => app.isFcmEnable)
            .map((app) => app.appIdx),
        );
      } else {
        setSelectedAppIds([]);
      }
    },
    [appEquipments],
  );

  const columns: CustomColumn<Api.Response.AppItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "appIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "고객사",
        accessor: "clientName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "ID",
        accessor: "appId",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value }, row }: CellProps<Api.Response.AppItem>) => (
          <ClickableCell
            value={value as string}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "앱명",
        accessor: "appName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "상태",
        accessor: "stateFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.AppItem>) => (
          <span style={{ color: value === 0 ? "#1cbb8c" : "#ff3d5f" }}>
            {value === 0 ? "정상" : "정지"}
          </span>
        ),
      },
      {
        Header: "FCM상태",
        accessor: "isFcmEnable",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.AppItem>) => (
          <span style={{ color: value ? "#1cbb8c" : "#ff3d5f" }}>
            {value ? "등록완료" : "미등록"}
          </span>
        ),
      },
      {
        Header: "요청건수",
        accessor: "reqCnt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "갱신일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "갱신자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
      },
    ],
    [handleRowClick],
  );

  return (
    <React.Fragment>
      <PageContainer breadcrumbItems={BREADCRUMB_ITEMS} title="App Management">
        <ControlPanelTypes
          placeholder="고객사명, 등록자, 수정자명으로 검색해주세요."
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          clearFilters={clearFilters}
          onSearch={handleSearch}
          dateRange={selectedDates}
          setDateRange={handleDateSearch}
          options1={clients}
          option1Type="number"
          selectedOption1={selectedClient}
          setSelectedOption1={handleClientSearch}
          options2={memberStatusOptions}
          option2Type="number"
          selectedOption2={selectedStatus}
          setSelectedOption2={handleStatusSearch}
          selectTitle1="고객사"
          selectTitle2="상태"
        />
        <CustomTableContainerTypes
          btnTitle="고객사 등록"
          btnTitle1="슬라이싱 요청"
          sortByIdx="appIdx"
          handleAddItem={handleAddAppEquipment}
          columns={columns || []}
          data={appEquipments || []}
          customPageSize={customPageSize}
          totalPage={totalPage}
          totalRecord={totalCustomers}
          setCustomPageSize={setCustomPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isLoading={loading}
          className="custom-header-css table align-middle table-nowrap"
          tableClassName="table-centered align-middle table-nowrap mb-0"
          theadClassName="text-muted table-light"
          selectedIds={selectedAppIds}
          handleCheckboxChange={handleCheckboxChange}
          handleCheckAll={handleCheckAll}
          openInputModal={openInputModal}
          showCheckboxColumn={true}
          filterCondition={(app) => app.isFcmEnable} // Filter based on isFcmEnable for AppEquipment
        />
        <SlicingRequestModal
          title="슬라이싱 요청파일을 선택해주세요"
          isLoading={loading}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          options={printerOptions || []}
          selectedOption={selectedPrinter}
          handleSave={handleSlicing}
          fileName={fileName}
          handleChange={handleSlicingChange}
          allowMultiple
          name="slicing-files"
        />
        {isSuccessModalOpen && (
          <ModalTypes
            title={successMessage}
            isFileInput={false}
            isLoading={loading}
            isOpen={isSuccessModalOpen}
            setIsOpen={setIsSuccessModalOpen}
            handleSave={() => setIsSuccessModalOpen(false)}
            fileName=""
            isSingleBtn
            mdSize="md"
          >
            {slicingResult && <ResultTable data={slicingResult} />}
          </ModalTypes>
        )}
        <ToastContainer />
      </PageContainer>
    </React.Fragment>
  );
};

export default AppEquipment;
