import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useStore from "../../zustandStore";
import useCustomerStore from "../../zustandStore/customerStore";
import PageContainer from "../../components/Common/PageContainer";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import * as Api from "@/api";
import Selector from "../../components/Profile/Selector";
import { stateOptions } from ".";
import FormInputTypes from "../../components/Profile/FormInputTypes";
import FormButtonsTypes from "../../components/Common/FormButtonsTypes";
import ConfirmModalTypes from "../../components/Common/ConfirmModalTypes";
import { ToastContainer, toast } from "react-toastify";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from "../../helpers/constants";
import { useLocalizedMessage } from "../../helpers/hooks";

type CustomerFormProps = {
  isDetailView: boolean;
};

const pureStateOptions = stateOptions.slice(1);

type ClientParams = Omit<Api.Response.Client, "clientIdx"> &
  Partial<Pick<Api.Response.Client, "clientIdx">>;

const defaultCustomer: ClientParams = {
  clientId: "",
  clientName: "",
  stateFlag: 0,
  vapidKey: "",
  storageBucket: "",
  projectId: "",
  messagingSenderId: "",
  measurementId: "",
  authDomain: "",
  appId: "",
  apiKey: "",
  registrationId: "",
  clientSecret: "",
};

const CustomerForm = ({ isDetailView }: CustomerFormProps) => {
  const { clientIdx } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useStore();
  const { loading, setLoading } = useCustomerStore();
  const getLocalizedMessage = useLocalizedMessage();

  const breadcrumbItems = [
    { title: "Customer Management", link: "#" },
    { title: `${isDetailView ? "Edit" : "Add"}`, link: "#" },
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState("");

  const openModal = (actionType: string) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const [customer, setCustomer] = useState(defaultCustomer);

  const getCustomerByIdx = useCallback(async () => {
    try {
      if (token) {
        Api.environment.setAccessToken(token);
      }
      if (!clientIdx) return;
      const response = await Api.Client.get(parseInt(clientIdx));

      const existingCustomer = response.data;

      if (existingCustomer) {
        setCustomer(existingCustomer);
      } else {
        setCustomer(defaultCustomer);
      }
    } catch (error) {
      console.error("Error fetching customer from API", error);
    }
  }, [clientIdx, token]);

  useEffect(() => {
    if (isDetailView) {
      getCustomerByIdx();
    }
  }, [getCustomerByIdx, isDetailView]);

  const handleChange = (e: {
    target: {
      name: string;
      value: string;
      type: string;
      checked: boolean;
      files: FileList | null;
    };
  }) => {
    const { name, value, type, checked, files } = e.target;
    const newValue = type === "checkbox" ? checked : files ? files[0] : value;

    setCustomer((prevCustomer) => ({ ...prevCustomer, [name]: newValue }));
  };

  const handleSaveCustomer = async () => {
    setLoading(true);
    const method = isDetailView ? "PUT" : "POST";

    if (!customer.clientName || !customer.clientId) {
      toast.error("고객사명과 고객사 ID를 입력해주세요", {
        autoClose: 3000,
      });
      return;
    }
    try {
      let response;
      if (method === "PUT") {
        if (typeof customer?.clientIdx !== "number") return;

        const updateData: Api.Request.UpdateClient = {
          clientIdx: customer.clientIdx,
          clientName: customer.clientName,
          stateFlag: customer.stateFlag,
          vapidKey: customer.vapidKey ?? "",
          storageBucket: customer.storageBucket ?? "",
          projectId: customer.projectId ?? "",
          messagingSenderId: customer.messagingSenderId ?? "",
          measurementId: customer.measurementId ?? "",
          authDomain: customer.authDomain ?? "",
          appId: customer.appId ?? "",
          apiKey: customer.apiKey ?? "",
        };
        response = await Api.Client.update(updateData);
      } else if (method === "POST") {
        const createData: Api.Request.AddClient = {
          clientId: customer.clientId,
          clientName: customer.clientName,
          stateFlag: customer.stateFlag,
          vapidKey: customer.vapidKey ?? "",
          storageBucket: customer.storageBucket ?? "",
          projectId: customer.projectId ?? "",
          messagingSenderId: customer.messagingSenderId ?? "",
          measurementId: customer.measurementId ?? "",
          authDomain: customer.authDomain ?? "",
          appId: customer.appId ?? "",
          apiKey: customer.apiKey ?? "",
        };
        response = await Api.Client.add(createData);
      }

      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        if (isDetailView) {
          navigate(`/customer${location.search}`);
        } else {
          navigate("/customer");
        }
      }, 1000);
    } catch (error) {
      if (error instanceof Error) {
        const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
        toast.error(errMessage, {
          autoClose: 3000,
        });
      } else {
        toast.error("An unexpected error occurred", {
          autoClose: 3000,
        });
      }
    } finally {
      setLoading(false);
      setIsOpen(false);
    }
  };

  const handleSecretUpdate = async () => {
    try {
      let response;
      if (clientIdx) {
        const id = { clientIdx: parseInt(clientIdx) };
        response = await Api.Client.updateSecret(id);
      }

      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, {
        autoClose: 3000,
      });

      getCustomerByIdx();
    } catch (error) {
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
      console.error(error);
    }
  };

  // const handleDeleteCustomer = async () => {
  //   setLoading(true);
  //   try {
  //   } catch (error) {}
  // };

  const handleCancel = () => {
    navigate(`/customer${location.search}`);
  };

  return (
    <>
      <PageContainer
        breadcrumbItems={breadcrumbItems}
        title="Customer Management"
      >
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <h4 className="card-title">고객사정보를 입력해주세요.</h4>
                <AvForm className="mt-4">
                  <Row>
                    <FormInputTypes
                      id="clientName"
                      label="고객사명"
                      placeholder="고객사명을 입력해주세요"
                      type="text"
                      value={customer.clientName || ""}
                      onChange={handleChange}
                      initialErrorMessage="고객사명을 입력해주세요"
                      validate={{
                        required: {
                          value: true,
                        },
                      }}
                      fullWidthLabel={false}
                      isRequired
                    />
                  </Row>
                  <Row>
                    <FormInputTypes
                      id="clientId"
                      label="고객사 ID"
                      placeholder="고객사 ID를 입력해주세요"
                      type="text"
                      value={customer.clientId || ""}
                      onChange={handleChange}
                      initialErrorMessage="고객사 ID를 입력해주세요"
                      validate={{
                        required: {
                          value: true,
                        },
                      }}
                      fullWidthLabel={false}
                      isRequired
                    />
                  </Row>
                  {isDetailView && (
                    <>
                      <Row>
                        <FormInputTypes
                          id="clientSecret"
                          label="API 시크릿키"
                          type="text"
                          value={customer.clientSecret || ""}
                          onChange={handleChange}
                          handleClick={handleSecretUpdate}
                          isButton
                          isRequired
                          fullWidthLabel={false}
                        />
                      </Row>
                      <Row>
                        <FormInputTypes
                          id="registrationId"
                          label="FCM 등록 ID"
                          type="text"
                          value={customer.registrationId || ""}
                          onChange={handleChange}
                          fullWidthLabel={false}
                        />
                      </Row>
                    </>
                  )}
                  <Row>
                    <FormInputTypes
                      id="vapidKey"
                      label="FCM VAPID KEY"
                      placeholder="FCM VAPID KEY를 입력해주세요"
                      type="text"
                      value={customer.vapidKey || ""}
                      onChange={handleChange}
                      fullWidthLabel={false}
                    />
                  </Row>
                  <Row>
                    <FormInputTypes
                      id="apiKey"
                      label="FCM API KEY"
                      placeholder="FCM API KEY를 입력해주세요"
                      type="text"
                      value={customer.apiKey || ""}
                      onChange={handleChange}
                      fullWidthLabel={false}
                    />
                  </Row>
                  <Row>
                    <FormInputTypes
                      id="authDomain"
                      label="FCM AUTH DOMAIN"
                      placeholder="FCM AUTH DOMAIN을 입력해주세요"
                      type="text"
                      value={customer.authDomain || ""}
                      onChange={handleChange}
                      fullWidthLabel={false}
                    />
                  </Row>
                  <Row>
                    <FormInputTypes
                      id="projectId"
                      label="FCM PROJECT ID"
                      placeholder="FCM PROJECT ID를 입력해주세요"
                      type="text"
                      value={customer.projectId || ""}
                      onChange={handleChange}
                      fullWidthLabel={false}
                    />
                  </Row>
                  <Row>
                    <FormInputTypes
                      id="storageBucket"
                      label="FCM STORAGE BUCKET"
                      placeholder="FCM STORAGE BUCKET를 입력해주세요"
                      type="text"
                      value={customer.storageBucket || ""}
                      onChange={handleChange}
                      fullWidthLabel={false}
                    />
                  </Row>
                  <Row>
                    <FormInputTypes
                      id="messagingSenderId"
                      label="FCM MESSAGING SENDER ID"
                      placeholder="FCM MESSAGING SENDER ID를 입력해주세요"
                      type="text"
                      value={customer.messagingSenderId || ""}
                      onChange={handleChange}
                      fullWidthLabel={false}
                    />
                  </Row>
                  <Row>
                    <FormInputTypes
                      id="appId"
                      label="FCM APP ID"
                      placeholder="FCM APP ID를 입력해주세요"
                      type="text"
                      value={customer.appId || ""}
                      onChange={handleChange}
                      fullWidthLabel={false}
                    />
                  </Row>
                  <Row>
                    <FormInputTypes
                      id="measurementId"
                      label="FCM MEASUREMENT ID"
                      placeholder="FCM MEASUREMENT ID를 입력해주세요"
                      type="text"
                      value={customer.measurementId || ""}
                      onChange={handleChange}
                      fullWidthLabel={false}
                    />
                  </Row>

                  <Row className="mb-3">
                    <Selector
                      label="서비스상태"
                      id="stateFlag"
                      options={pureStateOptions}
                      value={customer.stateFlag}
                      onChange={handleChange}
                      isRequired
                    />
                  </Row>
                  <FormButtonsTypes
                    isDetailView={isDetailView}
                    openModal={openModal}
                    handleCancel={handleCancel}
                    hideDeleteButton={true}
                  />
                  <ConfirmModalTypes
                    isLoading={loading}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    action={action}
                    handleSave={handleSaveCustomer}
                    // handleDelete={handleDeleteCustomer}
                  />
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </PageContainer>
    </>
  );
};

export default CustomerForm;
