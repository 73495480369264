import * as Api from "@/api";
import { useState } from "react";
import noImg from "../../assets/images/no-image.jpg";
import CardHeader from "./components/CardHeader";

type NewSubscriberProps = {
  data: Api.Response.NewSubscriber[];
};

const NewSubscriber = ({ data }: NewSubscriberProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="col-12 col-lg-4">
      <div className="card custom-card overflow-hidden">
        <CardHeader
          title="최근가입자"
          tooltipId="newSubscriberInfo"
          tooltipContent="최근30일동안 가입한 5명 <br />
              사용자 입니다."
          isHovered={isHovered}
          setIsHovered={() => setIsHovered((prev) => !prev)}
        />
        <div className="card-body p-0" style={{ minHeight: "21.5625rem" }}>
          <ul className="list-group list-group-flush mb-0">
            {data.map((user) => (
              <li className="list-group-item" key={user.userId}>
                <div className="d-flex flex-wrap align-items-center justify-content-between flex-xl-nowrap pl-3">
                  <div className="d-flex align-items-center">
                    <div className="me-2 lh-1 col-2 col-md-2">
                      <span className="avatar avatar-md avatar-rounded">
                        <img src={user.imgPath || noImg} alt="User Photo" />
                      </span>
                    </div>
                    <div
                      className="col-8 col-md-6"
                      style={{ minWidth: "200px" }}
                    >
                      <p className="mb-0">
                        <span className="fw-bold text-dark">
                          {user.nickName}
                        </span>{" "}
                        <span className=" fw-bold text-primary">
                          {user.country}
                        </span>
                      </p>
                      <span className="text-muted fs-14 fw-bold">
                        {user.userId}
                      </span>
                    </div>
                  </div>
                  <div className="col-2 col-md-2 text-end">
                    <span className="text-muted fs-12 mt-1 ms-1 fw-bold d-flex align-right">
                      {user.ip}
                    </span>
                  </div>
                  <div className="col-2 col-md-2 text-end">
                    <span className="text-muted fs-12 mt-1 ms-1 fw-bold">
                      {user.timeAgo}
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NewSubscriber;
