import * as Api from "@/api";
import { useEffect, useRef, useState } from "react";
import CardHeader from "./components/CardHeader";

type TopRequestRegionProps = {
  data: Api.Response.TopRequestRegion;
  selectedDays: number;
  onDaysSelection: (days: number) => void;
};

const TopRequestRegion = ({
  data,
  selectedDays,
  onDaysSelection,
}: TopRequestRegionProps) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!chartRef.current) return;

    const chartOptions = {
      series: [
        {
          name: "요청수",
          data: data.reqCntList,
        },
        {
          name: "요청자수",
          data: data.userCntList,
        },
      ],
      chart: {
        // stacked: true, // user goes on top of req
        toolbar: {
          show: false,
        },
        type: "bar",
        fontFamily: "'Roboto', sans-serif",
        fontWeight: "600",
        height: 290,
      },
      colors: [
        "var(--primary-color)",
        "rgb(227, 84, 212)",
        "rgb(255, 142, 111)",
        "rgb(255, 93, 159)",
      ],
      plotOptions: {
        bar: {
          columnWidth: "30%",
          borderRadiusApplication: "around",
          borderRadiusWhenStacked: "all",
          borderRadius: 3,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: "top",
        offsetX: 0,
        offsetY: 8,
        fontSize: "14px",
        markers: {
          width: 9,
          height: 9,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
      },
      grid: {
        borderColor: "rgba(0,0,0,0.1)",
        strokeDashArray: 3,
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        categories: data.countryList,
      },
      yaxis: {
        tickAmount: 4,
      },
    };

    // This is "areaChart"
    const chart = new ApexCharts(chartRef.current, chartOptions);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data]);

  return (
    <div className="col-12 col-lg-8">
      <div className="card custom-card" style={{ height: "31.38rem" }}>
        <CardHeader
          title="TOP10 요청지역"
          tooltipId="reqRegionInfo"
          tooltipContent="선택기간동안 지역별 요청수 및 <br />
              요청자수 입니다."
          isHovered={isHovered}
          setIsHovered={() => setIsHovered((prev) => !prev)}
          selectedDays={selectedDays}
          onDaysSelection={onDaysSelection}
          dayOptions={[30, 60, 90]}
        />
        <div className="card-body">
          <div className="mt-5">
            <div ref={chartRef}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopRequestRegion;
