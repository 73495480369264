import PageContainer from "../../components/Common/PageContainer";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import FormInputTypes from "../../components/Profile/FormInputTypes";
import * as Api from "@/api";
import { useCallback, useEffect, useState } from "react";
import Selector from "../../components/Profile/Selector";
import { stateOptions } from "../Customers";
import CustomFileInput from "../../components/Common/CustomFileInput";
import FormButtonsTypes from "../../components/Common/FormButtonsTypes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ConfirmModalTypes from "../../components/Common/ConfirmModalTypes";
import { toast, ToastContainer } from "react-toastify";
import { useLocalizedMessage } from "../../helpers/hooks";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from "../../helpers/constants";
import useStore from "../../zustandStore";
import { CardImgContainer } from "../Utility/styled";

type AccountFormProps = {
  isDetailView: boolean;
};

type AccountProps = Api.Request.AddAccount &
  Partial<
    Pick<
      Api.Response.Account,
      | "adminIdx"
      | "imgPath"
      | "regName"
      | "regId"
      | "regIdx"
      | "regDt"
      | "updName"
      | "updDt"
      | "updId"
      | "updIdx"
    >
  > & { adminPw?: string };

const DEFAULT_ACCOUNT: AccountProps = {
  adminName: "",
  adminId: "",
  adminPw: "",
  positionName: "",
  stateFlag: 0,
  slackId: "",
  file: undefined,
};

const pureStateOptions = stateOptions.slice(1);

const AccountForm = ({ isDetailView }: AccountFormProps) => {
  const breadcrumbItems = [
    { title: "Account Management", link: "#" },
    { title: `${isDetailView ? "Edit" : "Add"}`, link: "#" },
  ];
  const { token } = useStore();
  const { adminIdx } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const getLocalizedMessage = useLocalizedMessage();

  const [isLoading, setIsLoading] = useState(false);

  const [account, setAccount] = useState(DEFAULT_ACCOUNT);
  const [fileName, setFileName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState("");

  const getAccountByIdx = useCallback(async () => {
    try {
      if (token) {
        Api.environment.setAccessToken(token);
      }
      if (!adminIdx) return;
      const response = await Api.Account.get(parseInt(adminIdx));
      const existingAccount = response.data;
      if (existingAccount) {
        setAccount({ ...existingAccount, adminPw: "" });
      }
    } catch (error) {
      console.error("Error fetching Account from API", error);
    }
  }, [adminIdx, token]);

  useEffect(() => {
    if (isDetailView) {
      getAccountByIdx();
    }
  }, [getAccountByIdx, isDetailView]);

  const openModal = (actionType: string) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleChange = (e: {
    target: {
      name: string;
      value: string;
      files: FileList | null;
    };
  }) => {
    const { name, value, files } = e.target;
    const newValue = files ? files[0] : value;
    if (name === "file" && newValue instanceof File) {
      setFileName((newValue && newValue?.name) || "");
    }
    setAccount((prevAcc) => ({ ...prevAcc, [name]: newValue }));
  };

  const handleSaveAccount = async () => {
    if (
      !account.adminName ||
      !account.adminId ||
      (!isDetailView && !account.adminPw) ||
      !account.positionName
    ) {
      toast.error("(*) 필드 모두 입력해주세요", {
        autoClose: 3000,
      });
      return;
    }
    setIsLoading(true);

    try {
      const requestData = {
        adminName: account.adminName,
        adminId: account.adminId,
        adminPw: account.adminPw,
        positionName: account.positionName,
        stateFlag: account.stateFlag,
        slackId: account.slackId,
        file: account.file,
      };
      let response;

      if (isDetailView) {
        if (typeof account?.adminIdx !== "number") return;

        response = await Api.Account.update({
          ...requestData,
          adminIdx: account.adminIdx,
        });
      } else {
        response = await Api.Account.add(requestData);
      }

      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        navigate("/account");
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
      setIsOpen(false);
    }
  };

  const handleCancel = () => {
    navigate(`/account${location.search}`);
  };

  return (
    <>
      <PageContainer
        breadcrumbItems={breadcrumbItems}
        title="Account Management"
      >
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <h4 className="card-title">계정정보를 입력해주세요.</h4>
                <AvForm className="mt-4">
                  <Row>
                    <FormInputTypes
                      id="adminName"
                      label="관리자명"
                      placeholder="관리자명을 입력해주세요"
                      type="text"
                      value={account.adminName || ""}
                      onChange={handleChange}
                      initialErrorMessage="관리자명을 입력해주세요"
                      validate={{
                        required: {
                          value: true,
                        },
                      }}
                      fullWidthLabel={false}
                      isRequired
                    />
                  </Row>
                  <Row>
                    <FormInputTypes
                      id="adminId"
                      label="아이디"
                      placeholder="아이디를 입력해주세요"
                      type="text"
                      value={account.adminId || ""}
                      onChange={handleChange}
                      initialErrorMessage="아이디를 입력해주세요"
                      validate={{
                        required: {
                          value: true,
                        },
                      }}
                      fullWidthLabel={false}
                      isRequired
                    />
                  </Row>
                  <Row>
                    <FormInputTypes
                      id="adminPw"
                      label="비밀번호"
                      placeholder="비밀번호를 입력해주세요"
                      type="password"
                      value={!isDetailView ? account.adminPw : "******"}
                      onChange={handleChange}
                      initialErrorMessage={
                        !isDetailView ? `비밀번호를 입력해주세요` : ""
                      }
                      validate={{
                        required: {
                          value: !isDetailView,
                        },
                      }}
                      fullWidthLabel={false}
                      isRequired={!isDetailView}
                    />
                  </Row>
                  <Row>
                    <FormInputTypes
                      id="positionName"
                      label="직책"
                      placeholder="직책을 입력해주세요"
                      type="text"
                      value={account.positionName || ""}
                      onChange={handleChange}
                      initialErrorMessage="직책을 입력해주세요"
                      validate={{
                        required: {
                          value: true,
                        },
                      }}
                      fullWidthLabel={false}
                      isRequired
                    />
                  </Row>
                  <Row className="mb-3">
                    <Selector
                      label="계정상태"
                      id="stateFlag"
                      options={pureStateOptions}
                      value={account.stateFlag}
                      onChange={handleChange}
                      isRequired
                    />
                  </Row>
                  <Row>
                    <FormInputTypes
                      id="slackId"
                      label="슬랙아이디"
                      placeholder="슬랙아이디를 입력해주세요"
                      type="text"
                      value={account.slackId || ""}
                      onChange={handleChange}
                      fullWidthLabel={false}
                    />
                  </Row>
                  <Row className="mb-3">
                    <Label
                      htmlFor="customFileInput"
                      className="col-md-2 col-form-label"
                    >
                      프로필 이미지
                    </Label>
                    <CustomFileInput
                      name="file"
                      fileName={fileName}
                      onChange={handleChange}
                    />
                  </Row>
                  {isDetailView && !account.file && account?.imgPath && (
                    <Row className="mb-2">
                      <Col>
                        <Label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        />
                        <CardImgContainer
                          style={{ marginLeft: "4px" }}
                          src={account.imgPath}
                          alt="Profile"
                          className="rounded avatar-lg"
                        />
                      </Col>
                    </Row>
                  )}
                  <FormButtonsTypes
                    isDetailView={isDetailView}
                    openModal={openModal}
                    handleCancel={handleCancel}
                    hideDeleteButton={true}
                  />
                  <ConfirmModalTypes
                    isLoading={isLoading}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    action={action}
                    handleSave={handleSaveAccount}
                  />
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </PageContainer>
    </>
  );
};

export default AccountForm;
