import { useEffect, useRef, useState } from "react";
import * as Api from "@/api";
import CardHeader from "./components/CardHeader";
import { getDate } from "./utils";

type ApiRequestProps = {
  data: Api.Response.ApiRequestState;
  selectedDays: number;
  onDaysSelection: (days: number) => void;
};
const ApiRequest = ({
  data,
  selectedDays,
  onDaysSelection,
}: ApiRequestProps) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!chartRef.current) return;
    const chartOptions = {
      series: [
        {
          type: "line",
          name: "API요청",
          data: data.apiList,
        },
        {
          type: "line",
          name: "AI요청",
          data: data.aiList,
        },
      ],
      chart: {
        type: "line",
        height: 290,
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: false,
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 6,
          left: 1,
          blur: 4,
          color: "#000",
          opacity: 0.12,
        },
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
        padding: {
          top: 2,
          right: 2,
          bottom: 2,
          left: 2,
        },
        borderColor: "#f1f1f1",
        strokeDashArray: 3,
      },
      markers: {
        size: 4,
        hover: {
          size: 3,
        },
      },
      colors: ["rgb(54,253,48)", "rgb(230,0,125)"],
      stroke: {
        curve: "straight",
        width: 2,
        dashArray: 2,
      },
      legend: {
        show: true,
        position: "top",
        fontSize: "10px",
        fontFamily: "Poppins",
        markers: {
          width: 7,
          height: 7,
        },
      },
      yaxis: {
        Show: true,
        labels: {
          show: true,
        },
      },
      xaxis: {
        show: false,
        type: "day",
        categories: data.dateList,
        labels: {
          rotate: -45,
          rotateAlways: true,
          formatter: function (value: string) {
            return getDate(value);
          },
        },
        axisBorder: {
          show: false,
          color: "rgba(119, 119, 142, 0.05)",
          offsetX: 0,
          offsetY: 0,
        },
      },
    };

    // This is "apiChart"
    const chart = new ApexCharts(chartRef.current, chartOptions);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data]);

  return (
    <div className="col-12 col-lg-8">
      <div className="card custom-card">
        <CardHeader
          title="API요청현황"
          tooltipId="apiReqInfo"
          tooltipContent="선택기간동안 API를 요청한 수<br /> 입니다."
          isHovered={isHovered}
          setIsHovered={() => setIsHovered((prev) => !prev)}
          selectedDays={selectedDays}
          onDaysSelection={onDaysSelection}
          dayOptions={[7, 14, 30]}
        />
        <div className="card-body">
          <div style={{ minHeight: "20.625rem" }}>
            <div ref={chartRef}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiRequest;
