import { Navigate } from "react-router-dom";

// Authentication related pages
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Pages Calendar
import Calendar from "../pages/Calendar/Calendar";

// Pages Component
import Chat from "../pages/Chat/Chat";

//Ecommerce Pages
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct";
import EcommerceCart from "../pages/Ecommerce/EcommerceCart";
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index";
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index";
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail";
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index";
import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index";

//Email
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ChartsKnob from "../pages/Charts/jquery-knob";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";

//Icons
import DripiIcons from "../pages/Icons/DripiIcons";
import FontAwesome from "../pages/Icons/FontAwesome";
import MaterialDesign from "../pages/Icons/MaterialDesign";
import RemixIcons from "../pages/Icons/RemixIcons";

//Utility
import CommingSoon from "../pages/Utility/CommingSoon";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";
import FAQs from "../pages/Utility/FAQs";
import Maintenance from "../pages/Utility/Maintenance";
import Pricing from "../pages/Utility/Pricing";
import StarterPage from "../pages/Utility/StarterPage";
import Timeline from "../pages/Utility/Timeline";

// Forms
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormElements from "../pages/Forms/FormElements";
import FormMask from "../pages/Forms/FormMask";
import FormUpload from "../pages/Forms/FormUpload";
import FormValidations from "../pages/Forms/FormValidations";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";

//Ui
import UIRoundSlider from "../pages/Ui/UIRoundSlider";
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiRating from "../pages/Ui/UiRating";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiNotifications from "../pages/Ui/ui-notifications";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import EditableTables from "../pages/Tables/EditableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";

// Inner Authentication
import EmailTemplate from "@/pages/EmailTemplate";
import EmailTemplateDetails from "@/pages/EmailTemplate/EmailTemplateDetails";
import EmailTemplateForm from "@/pages/EmailTemplate/EmailTemplateForm";
import SendEmail from "@/pages/SendEmail";
import EmailDetails from "@/pages/SendEmail/EmailDetails";
import EmailList from "@/pages/SendEmail/EmailList";
import Account from "../pages/Account";
import AccountDetails from "../pages/Account/AccountDetails";
import AccountForm from "../pages/Account/AccountForm";
import AppEquipment from "../pages/AppEquipment";
import AppEquipmentDetails from "../pages/AppEquipment/AppEquipmentDetails";
import AppEquipmentForm from "../pages/AppEquipment/AppEquipmentForm";
import AppType from "../pages/AppType";
import AppTypeDetails from "../pages/AppType/AppTypeDetails";
import AppTypeForm from "../pages/AppType/AppTypeForm";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import Customers from "../pages/Customers";
import CustomerDetails from "../pages/Customers/CustomerDetails";
import CustomerForm from "../pages/Customers/CustomerForm";
import EditRequest from "../pages/EditRequest";
import Material from "../pages/Material";
import MaterialDetails from "../pages/Material/MaterialDetails";
import MaterialForm from "../pages/Material/MaterialForm";
import Printer from "../pages/Printer";
import PrinterDetails from "../pages/Printer/PrinterDetails";
import PrinterForm from "../pages/Printer/PrinterForm";
import PrintingProfile from "../pages/PrintingProfile";
import PrintingProfileDetails from "../pages/PrintingProfile/PrintingProfileDetails";
import PrintingProfileForm from "../pages/PrintingProfile/PrintingProfileForm";
import Project from "../pages/Project";
import SlicingRequest from "../pages/Slicing";
import SupportProfile from "../pages/SupportProfile";
import SupportProfileDetails from "../pages/SupportProfile/SupportProfileDetails";
import SupportProfileForm from "../pages/SupportProfile/SupportProfileForm";
import Users from "../pages/Users";
import UserDetails from "../pages/Users/UserDetails";
import UserForm from "../pages/Users/UserForm";
import Version from "../pages/Version";
import VersionDetails from "../pages/Version/VersionDetails";
import VersionForm from "../pages/Version/VersionForm";

const authProtectedRoutes = [
  //Users
  { path: "/users", component: <Users /> },
  { path: "/users/:userIdx", component: <UserDetails /> },
  { path: "/users/new", component: <UserForm /> },
  //Material
  { path: "/material", component: <Material /> },
  { path: "/material/new", component: <MaterialForm /> },
  { path: "/material/:materialIdx", component: <MaterialDetails /> },
  //Printer
  { path: "/printer", component: <Printer /> },
  { path: "/printer/new", component: <PrinterForm /> },
  { path: "/printer/:printerIdx", component: <PrinterDetails /> },
  //Printing Profile
  { path: "/printing-profile", component: <PrintingProfile /> },
  { path: "/printing-profile/new", component: <PrintingProfileForm /> },
  {
    path: "/printing-profile/:printingIdx",
    component: <PrintingProfileDetails />,
  },
  //Support Profile
  { path: "/support-profile", component: <SupportProfile /> },
  { path: "/support-profile/new", component: <SupportProfileForm /> },
  {
    path: "/support-profile/:supportIdx",
    component: <SupportProfileDetails />,
  },
  //App Type
  { path: "/app-type", component: <AppType /> },
  { path: "/app-type/new", component: <AppTypeForm /> },
  { path: "/app-type/:applicationsIdx", component: <AppTypeDetails /> },

  //Version Management
  { path: "/app-version", component: <Version /> },
  { path: "/app-version/new", component: <VersionForm /> },
  { path: "/app-version/:versionIdx", component: <VersionDetails /> },

  //Customer
  { path: "/customer", component: <Customers /> },
  { path: "/customer/new", component: <CustomerForm /> },
  { path: "/customer/:clientIdx", component: <CustomerDetails /> },

  //App-Equipment
  { path: "/app-equipment", component: <AppEquipment /> },
  { path: "/app-equipment/new", component: <AppEquipmentForm /> },
  { path: "/app-equipment/:appIdx", component: <AppEquipmentDetails /> },

  //Slicing Requests
  { path: "/slicing-request", component: <SlicingRequest /> },

  //Project Management
  { path: "/project", component: <Project /> },

  //Edit Request Management
  { path: "/edit-request", component: <EditRequest /> },

  //Account Management
  { path: "/account", component: <Account /> },
  { path: "/account/new", component: <AccountForm /> },
  { path: "/account/:adminIdx", component: <AccountDetails /> },

  // Send Email
  { path: "/send-email", component: <SendEmail /> },
  { path: "/email-history", component: <EmailList /> },
  { path: "/email-history/:histIdx", component: <EmailDetails /> },

  // Email Template Management
  { path: "/email-template", component: <EmailTemplate /> },
  { path: "/email-template/new", component: <EmailTemplateForm /> },
  { path: "/email-template/:tempIdx", component: <EmailTemplateDetails /> },

  // Tables
  { path: "/basic-tables", component: <BasicTables /> },
  { path: "/datatable-table", component: <DatatableTables /> },
  { path: "/responsive-table", component: <ResponsiveTables /> },
  { path: "/editable-table", component: <EditableTables /> },

  // Ui
  { path: "/ui-alerts", component: <UiAlert /> },
  { path: "/ui-buttons", component: <UiButtons /> },
  { path: "/ui-cards", component: <UiCards /> },
  { path: "/ui-carousel", component: <UiCarousel /> },
  { path: "/ui-dropdowns", component: <UiDropdown /> },
  { path: "/ui-general", component: <UiGeneral /> },
  { path: "/ui-grid", component: <UiGrid /> },
  { path: "/ui-images", component: <UiImages /> },
  { path: "/ui-lightbox", component: <UiLightbox /> },
  { path: "/ui-modals", component: <UiModal /> },
  // {path: "/ui-offcanvas", component: <UiDrawer/>},
  { path: "/ui-progressbars", component: <UiProgressbar /> },
  { path: "/ui-tabs-accordions", component: <UiTabsAccordions /> },
  { path: "/ui-typography", component: <UiTypography /> },
  { path: "/ui-video", component: <UiVideo /> },
  { path: "/ui-session-timeout", component: <UiSessionTimeout /> },
  { path: "/ui-rating", component: <UiRating /> },
  { path: "/ui-rangeslider", component: <UiRangeSlider /> },
  { path: "/ui-notifications", component: <UiNotifications /> },
  { path: "/ui-roundslider", component: <UIRoundSlider /> },

  // Forms
  { path: "/form-elements", component: <FormElements /> },
  { path: "/form-advanced", component: <FormAdvanced /> },
  { path: "/form-editors", component: <FormEditors /> },
  { path: "/form-mask", component: <FormMask /> },
  { path: "/form-file-upload", component: <FormUpload /> },
  { path: "/form-wizard", component: <FormWizard /> },
  { path: "/form-validation", component: <FormValidations /> },
  { path: "/form-xeditable", component: <FormXeditable /> },

  //Utility
  { path: "/starter", component: <StarterPage /> },
  { path: "/timeline", component: <Timeline /> },
  { path: "/faqs", component: <FAQs /> },
  { path: "/pricing", component: <Pricing /> },

  //Icons
  { path: "/icons-remix", component: <RemixIcons /> },
  { path: "/material-design", component: <MaterialDesign /> },
  { path: "/dripicons", component: <DripiIcons /> },
  { path: "/font-awesome-5", component: <FontAwesome /> },

  // Maps
  { path: "/google-maps", component: <MapsGoogle /> },
  { path: "/vector-maps", component: <MapsVector /> },

  //Charts
  { path: "/apex-charts", component: <ChartApex /> },
  { path: "/chartjs", component: <ChartjsChart /> },
  { path: "/charts-sparkline", component: <SparklineChart /> },
  { path: "/charts-knob", component: <ChartsKnob /> },

  //Email
  { path: "/email-inbox", component: <EmailInbox /> },
  { path: "/email-read", component: <EmailRead /> },

  //Ecommerce

  { path: "/ecommerce-products", component: <EcommerceProducts /> },
  {
    path: "/ecommerce-product-detail/:id",
    component: <EcommerceProductDetail />,
  },
  { path: "/ecommerce-orders", component: <EcommerceOrders /> },
  { path: "/ecommerce-customers", component: <EcommerceCustomers /> },
  { path: "/ecommerce-cart", component: <EcommerceCart /> },
  { path: "/ecommerce-checkout", component: <EcommerceCheckout /> },
  { path: "/ecommerce-shops", component: <EcommerceShops /> },
  { path: "/ecommerce-add-product", component: <EcommerceAddProduct /> },

  //chat
  { path: "/chat", component: <Chat /> },

  //calendar
  { path: "/calendar", component: <Calendar /> },

  { path: "/dashboard", component: <Dashboard /> },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/lock-screen", component: <AuthLockScreen /> },

  // Authentication Inner
  { path: "/auth-login", component: <Login1 /> },
  { path: "/auth-register", component: <Register1 /> },
  { path: "/auth-recoverpw", component: <ForgetPwd1 /> },

  { path: "/maintenance", component: <Maintenance /> },
  { path: "/comingsoon", component: <CommingSoon /> },
  { path: "/404", component: <Error404 /> },
  { path: "/500", component: <Error500 /> },
];

export { authProtectedRoutes, publicRoutes };
