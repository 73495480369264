import React, { useEffect, useState } from "react";

import { Row, Col, Input, Button, Container, Label } from "reactstrap";

import { Link } from "react-router-dom";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as Api from "@/api";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";

import { useNavigate } from "react-router-dom";
import useStore from "../../zustandStore";
import OtpInput from "./components/OtpInput";
import CountdownTimer from "./components/CountdownTimer";
import { DEFAULT_ERROR_MESSAGE } from "../../helpers/constants";
import { useLocalizedMessage } from "../../helpers/hooks";

const Login = () => {
  const navigate = useNavigate();
  const setToken = useStore((state) => state.setToken);
  const setLoginError = useStore((state) => state.setLoginError);
  const getLocalizedMessage = useLocalizedMessage();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false); // State for "Remember Me"
  const [isOtpView, setIsOtpView] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpExpireTime, setOtpExpireTime] = useState("");

  useEffect(() => {
    const storedRememberMe = localStorage.getItem("rememberMe") === "true";
    setRememberMe(storedRememberMe);
    if (storedRememberMe) {
      const storedUsername = localStorage.getItem("username") || "";
      setUsername(storedUsername);
    }
  }, []);

  const handleSubmit = async (event, values) => {
    event.preventDefault();

    try {
      const response = await Api.Admin.requestOtp({
        adminId: values.username,
        adminPw: values.password,
      });

      if (rememberMe) {
        localStorage.setItem("rememberMe", "true");
        localStorage.setItem("username", values.username);
      } else {
        localStorage.removeItem("rememberMe");
        localStorage.removeItem("username");
      }
      setIsOtpView(true);
      setOtpExpireTime(response.data.expireTimeStr);
      setLoginError(null);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
      setLoginError(errMessage);
    }
  };

  const handleLogin = async () => {
    try {
      const requestData = {
        adminId: username,
        adminPw: password,
        otp: otp,
      };
      const response = await Api.Admin.login(requestData);
      Api.environment.setAccessToken(response.data.accessToken);
      setToken(response.data.accessToken, response.data.expireTime);
      navigate("/dashboard");
    } catch (error) {
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
      setLoginError(errMessage);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0 bg-white">
          <Row className="g-0">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={otpExpireTime ? 12 : 9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" className="">
                              <img
                                src={logodark}
                                alt=""
                                height="36"
                                className="auth-logo logo-dark mx-auto"
                              />
                              <img
                                src={logolight}
                                alt=""
                                height="36"
                                className="auth-logo logo-light mx-auto"
                              />
                            </Link>
                          </div>

                          <h4 className="font-size-18 mt-4">Welcome Back !</h4>
                          <p className="text-muted">
                            Sign in to continue to Metamorp.
                          </p>
                        </div>
                        {isOtpView ? (
                          <>
                            <OtpInput
                              length={6}
                              onChange={(otp) => setOtp(otp)}
                            />
                            <div className="mt-4 text-center">
                              <p className="mb-0">Please enter the OTP code</p>
                              {otpExpireTime && (
                                <CountdownTimer expireTimeStr={otpExpireTime} />
                              )}
                              <Button
                                color="primary"
                                className="w-md waves-effect waves-light"
                                type="button"
                                onClick={handleLogin}
                              >
                                Continue
                              </Button>
                            </div>
                            <ToastContainer />
                          </>
                        ) : (
                          <div className="p-2 mt-5">
                            <AvForm
                              className="form-horizontal"
                              onValidSubmit={handleSubmit}
                            >
                              <div className="auth-form-group-custom mb-4">
                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="username">ID</Label>
                                <AvField
                                  name="username"
                                  value={username}
                                  type="text"
                                  className="form-control"
                                  id="username"
                                  placeholder="Enter id"
                                  onChange={(e) => setUsername(e.target.value)}
                                  required
                                />
                              </div>
                              <div className="auth-form-group-custom mb-4">
                                <span onClick={togglePasswordVisibility}>
                                  {showPassword ? (
                                    <i className="ri-eye-line auti-custom-input-icon"></i>
                                  ) : (
                                    <i className="ri-eye-off-line auti-custom-input-icon"></i>
                                  )}
                                </span>

                                <Label htmlFor="userpassword">Password</Label>
                                <AvField
                                  name="password"
                                  value={password}
                                  type={showPassword ? "text" : "password"}
                                  className="form-control"
                                  id="userpassword"
                                  placeholder="Enter password"
                                  onChange={(e) => setPassword(e.target.value)}
                                  required
                                />
                              </div>

                              <div className="form-check">
                                <Input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customControlInline"
                                  checked={rememberMe}
                                  onChange={(e) =>
                                    setRememberMe(e.target.checked)
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="customControlInline"
                                >
                                  Remember me
                                </Label>
                              </div>

                              <div className="mt-4 text-center">
                                <Button
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Log In
                                </Button>
                              </div>
                            </AvForm>
                            <ToastContainer />
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
