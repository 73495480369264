import * as Api from "@/api";
import { useState } from "react";
import noImg from "../../assets/images/no-image.jpg";
import CardHeader from "./components/CardHeader";

type TopConnectedProps = {
  data: Api.Response.TopConnectedUser[];
};

const getColorClass = (index: number) => {
  const colorClasses = [
    "primary",
    "primary1",
    "primary2",
    "primary3",
    "success",
  ];
  return colorClasses[index % colorClasses.length];
};

const TopConnected = ({ data }: TopConnectedProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="col-12 col-lg-4">
      <div className="card custom-card">
        <CardHeader
          title="상위접속자"
          tooltipId="connectInfo"
          tooltipContent="최근30일동안 접속한 상위 5명 <br />
              사용자 입니다."
          isHovered={isHovered}
          setIsHovered={() => setIsHovered((prev) => !prev)}
        />
        <div className="card-body">
          <div>
            <div className="progress-stacked progress-sm mb-2 gap-1">
              {data.map((user, index) => (
                <div
                  key={index}
                  className={`progress-bar bg-${getColorClass(index)}`}
                  role="progressbar"
                  style={{ width: `${user.percentageChange}%` }}
                  aria-valuenow={user.percentageChange}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              ))}
            </div>
          </div>
          <div className="table-responsive custom-pd">
            <table
              className="table text-nowrap text-center"
              style={{ padding: "8px !important" }}
            >
              <thead>
                <tr>
                  <th>프로필</th>
                  <th>ID</th>
                  <th>접속일수</th>
                  <th>전월대비</th>
                </tr>
              </thead>
              <tbody className="align-middle text-center">
                {data.map((user, index) => (
                  <tr key={user.id}>
                    <td
                      style={{
                        padding: "0px",
                      }}
                    >
                      <span className="avatar avatar-md align-middle">
                        <img src={user.imgPath || noImg} alt="User Photo" />
                      </span>
                    </td>
                    <td style={{ paddingLeft: "0rem" }}>
                      <div
                        className="col-8 col-md-6 text-start"
                        style={{ width: "10rem" }}
                      >
                        <p className="mb-0">
                          <span className="fw-bold text-dark">
                            {user.country}
                          </span>{" "}
                        </p>
                        <span className="text-muted fs-14 fw-bold">
                          {user.id}
                        </span>
                      </div>
                    </td>
                    <td>{user.connectedCnt}</td>
                    <td>
                      <span className={`badge bg-${getColorClass(index)}`}>
                        {user.percentageChange}%{" "}
                        {user.percentageChange > 0 ? (
                          <i className="ri-arrow-right-up-line"></i>
                        ) : (
                          <i className="ri-arrow-right-down-line"></i>
                        )}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopConnected;
