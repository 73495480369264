import React, { useCallback, useEffect, useState } from "react";
import PageContainer from "../../components/Common/PageContainer";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CardImgContainer, HoverImage, RowContainer } from "../Utility/styled";
import * as Api from "@/api";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";

import ConfirmModal from "../../components/Common/Modal";
import FormButtons from "../../components/Common/FormButtons";
import FormInput from "../../components/Profile/FormInput";
import Selector from "../../components/Profile/Selector";
import Checkbox from "../../components/Profile/Checkbox";
import useStore from "../../zustandStore";
import usePrinterStore from "../../zustandStore/printerStore";
import CustomFileInput from "../../components/Common/CustomFileInput";
import { useLocalizedMessage } from "../../helpers/hooks";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from "../../helpers/constants";
import CustomSelector from "../../components/Common/CustomSelector";

const inversionTypes = [
  { label: "반전하지않음", value: "none" },
  { label: "X축반전", value: "x" },
  { label: "Y축반전", value: "y" },
  { label: "XY축반전", value: "xy" },
];

const defaultPrinter = {
  printerName: "",
  manufName: "",
  displayFlag: 1,
  exportType: "",
  inversionType: inversionTypes[0].value,
  buildX: "",
  buildY: "",
  buildZ: "",
  resolutionX: "",
  resolutionY: "",
  file: null,
};

const PrinterForm = ({ isDetailView }) => {
  const navigate = useNavigate();
  const { printerIdx } = useParams();
  const { loading, setLoading } = usePrinterStore();
  const { token } = useStore();
  const location = useLocation();
  const getLocalizedMessage = useLocalizedMessage();

  const breadcrumbItems = [
    { title: "Printer Management", link: "#" },
    { title: `${isDetailView ? "Edit" : "Add"}`, link: "#" },
  ];

  const [printer, setPrinter] = useState(defaultPrinter);
  const [fileName, setFileName] = useState(printer.file?.name || "");
  const [exportTypes, setExportTypes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  const fetchSaveFormats = useCallback(async () => {
    try {
      const response = await Api.Common.getSaveFormatList();
      setExportTypes(response.data);
      setPrinter((prevPrinter) => ({
        ...prevPrinter,
        exportType: response.data[0].value,
      }));
    } catch (error) {
      console.error("Error fetching save formats", error);
    }
  }, []);

  const getPrinterByIdx = useCallback(async () => {
    try {
      const response = await Api.Printer.get(printerIdx);
      const existingPrinter = response.data;
      if (existingPrinter) {
        setPrinter(existingPrinter);
      } else {
        setPrinter(defaultPrinter);
      }
    } catch (error) {
      console.error("Error fetching printer from API", error);
    }
  }, [printerIdx]);

  useEffect(() => {
    if (token) {
      Api.environment.setAccessToken(token);
      fetchSaveFormats();

      if (isDetailView) {
        getPrinterByIdx();
      }
    }
  }, [token, fetchSaveFormats, getPrinterByIdx, isDetailView]);

  const handleValidationError = (id, errorMessage) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [id]: errorMessage,
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    const newValue = type === "checkbox" ? checked : files ? files[0] : value;
    if (name === "file") {
      setFileName(newValue ? newValue.name : "");
    }
    setPrinter((prevPrinter) => ({ ...prevPrinter, [name]: newValue }));
  };

  const openModal = (actionType) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleSavePrinter = async (e) => {
    if (e) e.preventDefault();

    if (Object.values(validationErrors).some((error) => error !== "")) {
      toast.error("저장하시기 전에 양식의 오류를 수정해 주세요", {
        autoClose: 3000,
      });
      return;
    }
    const validExportTypes = exportTypes.map((type) => type.value);

    if (
      !printer.printerName ||
      !printer.manufName ||
      !printer.buildX ||
      !printer.buildY ||
      !printer.buildZ ||
      !printer.resolutionX ||
      !printer.resolutionY ||
      !validExportTypes.includes(printer.exportType)
    ) {
      toast.error("(*) 있는 모든 필드 입력해주세요", {
        autoClose: 3000,
      });
      return;
    }

    setLoading(true);
    const method = isDetailView ? "PUT" : "POST";

    try {
      let response;
      if (method === "PUT") {
        response = await Api.Printer.update({
          printerIdx: printer.printerIdx,
          printerName: printer.printerName,
          manufName: printer.manufName,
          displayFlag: printer.displayFlag,
          exportType: printer.exportType,
          inversionType: printer.inversionType,
          buildX: printer.buildX,
          buildY: printer.buildY,
          buildZ: printer.buildZ,
          resolutionX: printer.resolutionX,
          resolutionY: printer.resolutionY,
          file: printer.file,
        });
      } else if (method === "POST") {
        response = await Api.Printer.add({
          printerName: printer.printerName,
          manufName: printer.manufName,
          displayFlag: printer.displayFlag,
          exportType: printer.exportType,
          inversionType: printer.inversionType,
          buildX: printer.buildX,
          buildY: printer.buildY,
          buildZ: printer.buildZ,
          resolutionX: printer.resolutionX,
          resolutionY: printer.resolutionY,
          file: printer.file,
        });
      }
      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        if (isDetailView) {
          navigate(`/printer${location.search}`);
        } else {
          navigate("/printer");
        }
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCopyPrinter = async (e) => {
    setLoading(true);

    try {
      const response = await Api.Printer.copy(printerIdx);
      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, { autoClose: 3000 });
      setTimeout(() => {
        navigate("/printer");
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePrinter = async () => {
    setLoading(true);

    try {
      const response = await Api.Printer.delete(printer.printerIdx);
      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, { autoClose: 3000 });
      setTimeout(() => {
        navigate("/printer");
      }, 1000);
    } catch (error) {
      console.error("Error deleting printer:", error);
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(`/printer${location.search}`);
  };

  return (
    <>
      <PageContainer
        breadcrumbItems={breadcrumbItems}
        title="Printer Management"
      >
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <h4 className="card-title">프린터 정보를 입력해주세요.</h4>
                <AvForm className="mt-4">
                  <Row>
                    <FormInput
                      id="printerName"
                      label="프린터명"
                      placeholder="프린터명을 입력해주세요"
                      type="text"
                      value={printer.printerName || ""}
                      onChange={handleChange}
                      errorMessage="프린터명을 입력해주세요"
                      validate={{ required: { value: true } }}
                      fullWidthLabel={false}
                      isRequired
                    />
                  </Row>
                  <Row>
                    <FormInput
                      id="manufName"
                      label="제조사명"
                      placeholder="제조사명을 입력해주세요"
                      type="text"
                      value={printer.manufName || ""}
                      onChange={handleChange}
                      errorMessage="제조사명을 입력해주세요"
                      validate={{ required: { value: true } }}
                      fullWidthLabel={false}
                      isRequired
                    />
                  </Row>
                  <Row className="mb-3">
                    <Label
                      htmlFor="customFileInput"
                      className="col-md-2 col-form-label"
                    >
                      이미지
                    </Label>
                    <CustomFileInput
                      fileName={fileName}
                      onChange={handleChange}
                    />
                  </Row>
                  {isDetailView && !printer.file && printer.imgPath && (
                    <RowContainer className="mb-2">
                      <Col>
                        <Label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        />
                        <CardImgContainer
                          src={printer.imgPath}
                          alt=""
                          className="avatar-lg"
                        />
                        <HoverImage
                          style={{
                            backgroundImage: `url(${printer.imgPath})`,
                          }}
                        />
                      </Col>
                    </RowContainer>
                  )}
                  <Row>
                    <FormInput
                      min={1}
                      id="buildX"
                      label="빌드사이즈X"
                      placeholder="빌드사이즈X를 입력해주세요"
                      type="number"
                      value={printer.buildX}
                      onChange={handleChange}
                      // errorMessage="빌드사이즈X를 입력해주세요"
                      validate={{ required: { value: true } }}
                      fullWidthLabel={false}
                      isRequired
                      onValidationError={handleValidationError}
                    />
                  </Row>
                  <Row>
                    <FormInput
                      min={1}
                      id="buildY"
                      label="빌드사이즈Y"
                      placeholder="빌드사이즈Y를 입력해주세요"
                      type="number"
                      value={printer.buildY}
                      onChange={handleChange}
                      // errorMessage="빌드사이즈Y를 입력해주세요"
                      validate={{ required: { value: true } }}
                      fullWidthLabel={false}
                      isRequired
                      onValidationError={handleValidationError}
                    />
                  </Row>
                  <Row>
                    <FormInput
                      min={1}
                      id="buildZ"
                      label="빌드사이즈Z"
                      placeholder="빌드사이즈Z를 입력해주세요"
                      type="number"
                      value={printer.buildZ}
                      onChange={handleChange}
                      // errorMessage="빌드사이즈Z를 입력해주세요"
                      validate={{ required: { value: true } }}
                      fullWidthLabel={false}
                      isRequired
                      onValidationError={handleValidationError}
                    />
                  </Row>
                  <Row>
                    <FormInput
                      min={1}
                      id="resolutionX"
                      label="해상도X"
                      placeholder="해상도X를 입력해주세요"
                      type="number"
                      value={printer.resolutionX}
                      onChange={handleChange}
                      // errorMessage="해상도X를 입력해주세요"
                      validate={{ required: { value: true } }}
                      fullWidthLabel={false}
                      isRequired
                      onValidationError={handleValidationError}
                    />
                  </Row>
                  <Row>
                    <FormInput
                      min={1}
                      id="resolutionY"
                      label="해상도Y"
                      placeholder="해상도Y를 입력해주세요"
                      type="number"
                      value={printer.resolutionY}
                      onChange={handleChange}
                      // errorMessage="해상도Y를 입력해주세요"
                      validate={{ required: { value: true } }}
                      fullWidthLabel={false}
                      isRequired
                      onValidationError={handleValidationError}
                    />
                  </Row>
                  <Row className="mb-3">
                    <Label
                      className="col-md-2 col-form-label"
                      htmlFor="exportType"
                    >
                      추출타입(<span className="text-danger">*</span>)
                    </Label>
                    <Col md={10}>
                      <CustomSelector
                        name="exportType"
                        placeholder="Select Type"
                        options={exportTypes.map((exportType) => ({
                          label: exportType.key,
                          value: exportType.value,
                        }))}
                        value={printer.exportType}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Selector
                      label="반전유형"
                      id="inversionType"
                      options={inversionTypes}
                      value={printer.inversionType}
                      onChange={handleChange}
                      isRequired
                    />
                  </Row>
                  <Row className="mb-2">
                    <Checkbox
                      label="표시여부"
                      id="displayFlag"
                      checked={printer.displayFlag === 1 ? true : false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setPrinter({ ...printer, displayFlag: 1 });
                        } else {
                          setPrinter({ ...printer, displayFlag: 0 });
                        }
                      }}
                      extraClass="mt-2"
                      fullWidthLabel={false}
                    />
                  </Row>

                  {isDetailView && (
                    <>
                      <Row className="mb-2">
                        <Checkbox
                          label="기본프린터여부"
                          id="defaultFlag"
                          checked={printer.defaultFlag}
                          extraClass="mt-2"
                          fullWidthLabel={false}
                          disabled
                          style={{ opacity: "0.5" }}
                        />
                      </Row>
                    </>
                  )}
                  <FormButtons
                    isDetailView={isDetailView}
                    openModal={openModal}
                    handleCancel={handleCancel}
                  />
                  <ConfirmModal
                    isLoading={loading}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    action={action}
                    handleSave={handleSavePrinter}
                    handleDelete={handleDeletePrinter}
                    handleCopy={handleCopyPrinter}
                  />
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </PageContainer>
    </>
  );
};

export default PrinterForm;
