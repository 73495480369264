import * as Api from "@/api";
import { useEffect, useRef, useState } from "react";
import CardHeader from "./components/CardHeader";
import { getDate } from "./utils";

type JoinChartProps = {
  data: Api.Response.RegistrationState;
  selectedDays: number;
  onDaysSelection: (days: number) => void;
};

const JoinChart = ({ data, selectedDays, onDaysSelection }: JoinChartProps) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!chartRef.current) return;

    const chartOptions = {
      series: [
        {
          type: "bar",
          name: "가입수",
          data: data.cntList,
        },
      ],
      chart: {
        type: "line",
        height: 290,
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 7,
          left: 1,
          blur: 3,
          color: ["#000", "#000"],
          opacity: 0.1,
        },
      },
      grid: {
        show: true,
        borderColor: "#f1f1f1",
        strokeDashArray: 3,
      },
      colors: ["rgb(91,103,245)"],
      fill: {
        type: "solid",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "35%",
          borderRadius: "4",
        },
      },
      stroke: {
        curve: "smooth",
        width: [1.5, 1.5],
        lineCap: "round",
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: "top",
        offsetX: 0,
        offsetY: 8,
        markers: {
          width: 8,
          height: 8,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        Show: true,
        labels: {
          show: true,
        },
      },
      xaxis: {
        show: false,
        type: "day",
        categories: data.dateList,
        labels: {
          formatter: function (value: string) {
            return getDate(value);
          },
        },
        axisBorder: {
          show: false,
          color: "rgba(119, 119, 142, 0.05)",
          offsetX: 0,
          offsetY: 0,
        },
      },
    };

    // This is "joinChart"
    const chart = new ApexCharts(chartRef.current, chartOptions);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data]);

  return (
    <div className="col-12 col-lg-4">
      <div className="card custom-card">
        <CardHeader
          title="회원가입현황"
          tooltipId="joinInfo"
          tooltipContent="선택기간동안 가입한 사용자수
              <br /> 입니다."
          isHovered={isHovered}
          setIsHovered={() => setIsHovered((prev) => !prev)}
          selectedDays={selectedDays}
          onDaysSelection={onDaysSelection}
          dayOptions={[7, 14, 30]}
        />
        <div className="card-body">
          <div style={{ minHeight: "20.625rem" }}>
            <div ref={chartRef}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinChart;
