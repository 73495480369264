import { useEffect, useRef, useState } from "react";
import * as Api from "@/api";
import * as d3 from "d3";
import * as topojson from "topojson-client";
import worldMap from "../../assets/xintra/json/world-50.v1.json";
import { getColor } from "./utils";
import CardHeader from "./components/CardHeader";

type RequestByCountryProps = {
  data: Api.Response.RequestCountry[];
  selectedDays: number;
  onDaysSelection: (days: number) => void;
};

const RequestByCountry = ({
  data,
  selectedDays,
  onDaysSelection,
}: RequestByCountryProps) => {
  const worldMapRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!worldMapRef.current) return;

    // This is  "mapChart"
    // Clear previous SVG and error message
    d3.select(worldMapRef.current).selectAll("svg").remove();

    // Set dimensions and margins
    const width = worldMapRef.current.clientWidth;
    const height = worldMapRef.current.clientHeight;

    // Create an SVG element with viewBox
    const svg = d3
      .select(worldMapRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", `0 0 ${width} ${height}`) // Add viewBox
      .attr("preserveAspectRatio", "xMidYMid meet"); // Preserve aspect ratio

    // Create a projection
    const projection = d3.geoMercator().translate([width / 2, height / 2]);
    // Create a path generator
    const path = d3.geoPath().projection(projection);
    // Create a group for map and markers
    const g = svg.append("g");

    try {
      // Draw the map
      g.append("path")
        .datum(
          topojson.feature(
            worldMap as any,
            (worldMap as any).objects.countries,
          ),
        )
        .attr("d", path as any)
        .attr("class", "map")
        .attr("fill", "#d6d6d6")
        .attr("stroke", "#8b8b8b")
        .attr("stroke-width", 0.5);

      // Add markers
      const markerGroup = g
        .selectAll(".marker")
        .data(data)
        .enter()
        .append("circle")
        .attr("class", "marker")
        .attr("cx", (d: Api.Response.RequestCountry) => {
          const proj = projection([Number(d.lon), Number(d.lat)]);
          return proj ? proj[0] : 0;
        })
        .attr("cy", (d: Api.Response.RequestCountry) => {
          const proj = projection([Number(d.lon), Number(d.lat)]);
          return proj ? proj[1] : 0;
        })
        .attr("r", 5)
        .attr("fill", (d) => getColor(d.cnt))
        .attr("stroke", "white")
        .attr("stroke-width", 1.5);

      // Create a tooltip div
      const tooltip = d3
        .select(worldMapRef.current)
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0)
        .style("position", "absolute")
        .style("color", "white")
        .style("background-color", "#333")
        .style("border", "1px solid #ddd")
        .style("padding", "10px")
        .style("border-radius", "5px")
        .style("pointer-events", "none");

      markerGroup
        .on("mouseover", function (event, d: Api.Response.RequestCountry) {
          tooltip.transition().duration(200).style("opacity", 0.9);
          tooltip
            .html(d.country + "<br/>" + "요청수: " + d.cnt + "회")
            .style("left", event.offsetX + 20 + "px")
            .style("top", event.offsetY - 10 + "px");
        })
        .on("mouseout", function () {
          tooltip.transition().duration(500).style("opacity", 0);
        });

      // Set up zoom functionality
      const zoom = d3
        .zoom<SVGSVGElement, unknown>()
        .scaleExtent([0.5, 8]) // Set minimum and maximum zoom levels
        .on("zoom", function (event: d3.D3ZoomEvent<SVGSVGElement, unknown>) {
          g.attr("transform", event.transform.toString());
          markerGroup.attr("r", 5 / event.transform.k);
          markerGroup.attr("stroke-width", 1.5 / event.transform.k);
        });

      svg.call(zoom as any);

      // Handle resizing
      const resizeMap = () => {
        if (!worldMapRef.current) return;

        const newWidth = worldMapRef.current.clientWidth;
        const newHeight = worldMapRef.current.clientHeight;

        // Resize SVG
        svg
          .attr("width", newWidth)
          .attr("height", newHeight)
          .attr("viewBox", `0 0 ${newWidth} ${newHeight}`);

        // Update projection
        projection
          .scale(newWidth / 2 / Math.PI)
          .translate([newWidth / 2, newHeight / 2]);

        // Redraw the map and markers
        g.selectAll("path").attr("d", path as any);
        markerGroup
          .attr("cx", (d: Api.Response.RequestCountry) => {
            const proj = projection([Number(d.lon), Number(d.lat)]);
            return proj ? proj[0] : 0;
          })
          .attr("cy", (d: Api.Response.RequestCountry) => {
            const proj = projection([Number(d.lon), Number(d.lat)]);
            return proj ? proj[1] : 0;
          });
      };
      window.addEventListener("resize", resizeMap);

      // Cleanup function
      return () => {
        window.removeEventListener("resize", resizeMap);
        d3.select(worldMapRef.current).select("svg").remove();
      };
    } catch (err) {
      console.error("Error rendering map:", err);
      setError(
        "An error occurred while rendering the map. Please try again later.",
      );
    }
  }, [data]);

  return (
    <div className="col-12 col-lg-8">
      <div className="card custom-card">
        <CardHeader
          title="국가별 요청분포"
          tooltipId="countryInfo"
          tooltipContent="선택기간동안 국가별
              <br />
              요청분포도입니다."
          isHovered={isHovered}
          setIsHovered={() => setIsHovered((prev) => !prev)}
          selectedDays={selectedDays}
          onDaysSelection={onDaysSelection}
          dayOptions={[30, 60, 90]}
        />
        <div className="card-body pt-0">
          <div style={{ minHeight: "25rem" }}>
            {error ? (
              <div className="alert alert-danger">{error}</div>
            ) : (
              <>
                <div
                  ref={worldMapRef}
                  style={{ width: "100%", height: "25rem" }}
                ></div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestByCountry;
