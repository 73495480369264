import * as Api from "@/api";
import { format } from "date-fns";
import queryString from "query-string";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ControlPanel from "../../components/Common/ControlPanel";
import PageContainer from "../../components/Common/PageContainer";

import CustomTableContainer from "../../components/Common/CustomTableContainer";
import useStore from "../../zustandStore";
import useSupportProfileStore from "../../zustandStore/supportProfileStore";
import {
  ClickableCell,
  DisplayCell,
  ImageCell,
  RegTypeCell,
} from "../Utility/CustomCells";
import { registrationOptions, showOptions } from "../Utility/Options";

const BREADCRUMB_ITEMS = [
  { title: "Support Profile", link: "#" },
  { title: "List", link: "#" },
];

const SupportProfile = () => {
  const { token } = useStore();
  const { supports, setSupports, loading, setLoading, setError } =
    useSupportProfileStore();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const [customPageSize, setCustomPageSize] = useState(
    parseInt(queryParams.pageSize) || 10,
  );
  const [currentPage, setCurrentPage] = useState(
    parseInt(queryParams.page) || 0,
  );
  const [totalPage, setTotalPage] = useState(0);
  const [totalSupports, setTotalSupports] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(queryParams.keyword || "");
  const [dateRange, setDateRange] = useState([
    queryParams.startDt || "",
    queryParams.endDt || "",
  ]);
  const [applicationTypes, setApplicationTypes] = useState([]);
  const [selectedAppType, setSelectedAppType] = useState(
    queryParams.appType || 0,
  );
  const [selectedRegType, setSelectedRegType] = useState(
    queryParams.regType || registrationOptions[0].value,
  );
  const [selectedShow, setSelectedShow] = useState(
    queryParams.show || showOptions[0].value,
  );

  const [clearTrigger, setClearTrigger] = useState(false);

  const handleRowClick = useCallback(
    (item) => {
      navigate(`/support-profile/${item.supportIdx}${window.location.search}`);
    },
    [navigate],
  );

  const handleAddSupport = () => {
    navigate(`/support-profile/new${window.location.search}`);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: dateRange[0],
      endDt: dateRange[1],
      appType: selectedAppType,
      regType: selectedRegType,
      show: selectedShow,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    dateRange,
    searchKeyword,
    selectedAppType,
    selectedRegType,
    selectedShow,
    location.pathname,
    navigate,
  ]);

  const fetchSupports = async () => {
    setLoading(true);
    setError(null);

    try {
      const requestData = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: searchKeyword,
        displayFlag: selectedShow,
        defaultFlag: selectedRegType,
        applicationsType: selectedAppType,
        startDt: dateRange[0] ? format(dateRange[0], "yyyy-MM-dd") : "",
        endDt: dateRange[1] ? format(dateRange[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.SupportProfile.getList(requestData);
      if (response.code !== 0) {
        throw new Error(response.message);
      }
      setTotalPage(response.paging.totalPage);
      setTotalSupports(response.paging.totalRecord);
      setSupports(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getApplicationTypes = useCallback(async () => {
    const response = await Api.Common.getApplicationList();
    const applications = response.data;

    const updatedApplications = [
      {
        name: "전체",
        imgPath: "",
        applicationsIdx: 0,
      },
      ...applications,
    ];

    setApplicationTypes(updatedApplications);
  }, []);

  useEffect(() => {
    if (token) {
      Api.environment.setAccessToken(token);
      getApplicationTypes();
    }
  }, [getApplicationTypes, token]);

  useEffect(() => {
    fetchSupports();
  }, [
    currentPage,
    customPageSize,
    selectedRegType,
    selectedShow,
    dateRange[1],
    selectedAppType,
    clearTrigger,
  ]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchSupports();
  };

  const handleAppTypeSearch = (newAppType) => {
    setSelectedAppType(newAppType);
    setCurrentPage(0);
  };

  const handleRegTypeSearch = (newRegType) => {
    setSelectedRegType(newRegType);
    setCurrentPage(0);
  };

  const handleShowSearch = (newShow) => {
    setSelectedShow(newShow);
    setCurrentPage(0);
  };

  const handleDateSearch = (newDateRange) => {
    setDateRange(newDateRange);
    setCurrentPage(0);
  };

  const clearFilters = () => {
    setSearchKeyword("");
    setDateRange("");
    setSelectedRegType(registrationOptions[0].value);
    setSelectedShow(showOptions[0].value);
    setCurrentPage(0);
    setSelectedAppType(0);
    setClearTrigger((prev) => !prev);
  };

  const columns = useMemo(
    () => [
      {
        Header: "No",
        accessor: "supportIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "고객사명",
        accessor: "clientName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "구분",
        accessor: "applicationsImgPath",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => <ImageCell value={value} />,
      },
      {
        Header: "프로파일명",
        accessor: "profileName",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value }, row }) => (
          <ClickableCell
            value={value}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "기본프로파일여부",
        accessor: "defaultFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => <RegTypeCell value={value} />,
      },
      {
        Header: "표시여부",
        accessor: "displayFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => <DisplayCell value={value} />,
      },
      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
    ],
    [handleRowClick],
  );

  return (
    <PageContainer breadcrumbItems={BREADCRUMB_ITEMS} title="Support Profile">
      <ControlPanel
        placeholder="프로파일명, 등록자, 수정자명으로 검색해주세요."
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        clearFilters={clearFilters}
        onSearch={handleSearch}
        dateRange={dateRange}
        setDateRange={handleDateSearch}
        options0={applicationTypes}
        selectedOption0={selectedAppType}
        setSelectedOption0={handleAppTypeSearch}
        options1={registrationOptions}
        options2={showOptions}
        selectedOption1={selectedRegType}
        setSelectedOption1={handleRegTypeSearch}
        selectedOption2={selectedShow}
        setSelectedOption2={handleShowSearch}
        selectTitle0="구분"
        selectTitle1="기본프로파일여부"
        selectTitle2="표시여부"
      />
      <CustomTableContainer
        btnTitle="프로파일 등록"
        sortByIdx="supportIdx"
        handleAddItem={handleAddSupport}
        handleRowClick={handleRowClick}
        columns={columns || []}
        data={supports || []}
        isPagination={true}
        customPageSize={customPageSize}
        totalPage={totalPage}
        totalRecord={totalSupports}
        setCustomPageSize={setCustomPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isLoading={loading}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
      />
    </PageContainer>
  );
};

export default SupportProfile;
