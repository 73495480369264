import { useEffect, useRef, useState } from "react";
import * as Api from "@/api";
import * as d3 from "d3";
import koreaMap from "../../assets/xintra/json/korea_geo.json";
import { getColor } from "./utils";
import CardHeader from "./components/CardHeader";

type RequestByRegionProps = {
  data: Api.Response.RequestRegion[];
  selectedDays: number;
  onDaysSelection: (days: number) => void;
};

const RequestByRegion = ({
  data,
  selectedDays,
  onDaysSelection,
}: RequestByRegionProps) => {
  const koreaMapRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState("");
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!koreaMapRef.current) return;

    const width = koreaMapRef.current.clientWidth;
    const height = koreaMapRef.current.clientHeight;

    // This is koreaChart
    // Clear previous SVG and error message
    d3.select(koreaMapRef.current).selectAll("svg").remove();
    setError("");

    const svg = d3
      .select(koreaMapRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("preserveAspectRatio", "xMidYMid meet");

    const projection = d3
      .geoMercator()
      .fitSize([width, height], koreaMap as any);

    const path = d3.geoPath().projection(projection);
    const g = svg.append("g");

    try {
      g.selectAll("path") // 지역 설정
        .data(koreaMap.features)
        .enter()
        .append("path")
        .attr("d", path as any)
        .attr("fill", "#d6d6d6")
        .attr("stroke", "#8b8b8b")
        .attr("stroke-width", 0.5);

      // Labels 추가
      g.selectAll("text")
        .data(koreaMap.features)
        .enter()
        .append("text")
        .attr("class", "map-label")
        .attr("transform", (d) => `translate(${path.centroid(d as any)})`)
        .attr("id", function (d) {
          return "label-" + d.properties.name_eng;
        })
        .attr("text-anchor", "middle")
        .attr("dy", ".35em")
        .style("fill", "black") // 레이블 색상 설정
        .style("font-size", "10px") // 폰트 크기 설정
        .text((d) => d.properties.name); // 레이블에 지역 이름 표시

      const markerGroup = g
        .selectAll(".marker")
        .data(data)
        .enter()
        .append("circle")
        .attr("class", "marker")
        .attr("cx", (d) => {
          const proj = projection([Number(d.lon), Number(d.lat)]);
          return proj ? proj[0] : 0;
        })
        .attr("cy", (d) => {
          const proj = projection([Number(d.lon), Number(d.lat)]);
          return proj ? proj[1] : 0;
        })
        .attr("r", 5)
        .attr("fill", (d) => getColor(d.cnt))
        .attr("stroke", "white")
        .attr("stroke-width", 1.5);

      const tooltip = d3
        .select(koreaMapRef.current)
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0)
        .style("position", "absolute")
        .style("color", "white")
        .style("background-color", "#333")
        .style("border", "1px solid #ddd")
        .style("padding", "10px")
        .style("border-radius", "5px")
        .style("pointer-events", "none");

      markerGroup
        .on("mouseover", function (event, d) {
          tooltip.transition().duration(200).style("opacity", 0.9);
          tooltip
            .html(d.regionName + "<br/>" + "요청수 " + d.cnt + "회")
            .style("left", event.offsetX + 20 + "px")
            .style("top", event.offsetY - 10 + "px");
        })
        .on("mouseout", function () {
          tooltip.transition().duration(500).style("opacity", 0);
        });

      const updateTextSize = (transform: d3.ZoomTransform) => {
        const scale = transform.k;
        const newFontSize = Math.max(10 / scale, 2);
        g.selectAll(".map-label").style("font-size", `${newFontSize}px`);
      };

      const zoom = d3
        .zoom()
        .scaleExtent([0.5, 8]) // Set minimum and maximum zoom levels
        .on("zoom", function (event) {
          g.attr("transform", event.transform);
          markerGroup.attr("r", 5 / event.transform.k);
          markerGroup.attr("stroke-width", 1.5 / event.transform.k); // Adjust border width on zoom
          updateTextSize(event.transform);
        });

      svg.call(zoom as any);

      // Handle resizing
      const resizeMap = () => {
        if (!koreaMapRef.current) return;

        const newWidth = koreaMapRef.current.clientWidth;
        const newHeight = koreaMapRef.current.clientHeight;

        // Resize SVG
        svg
          .attr("width", newWidth)
          .attr("height", newHeight)
          .attr("viewBox", `0 0 ${newWidth} ${newHeight}`);

        projection.fitSize([newWidth, newHeight], koreaMap as any);

        // Redraw the map and markers
        g.selectAll("path").attr("d", path as any);
        markerGroup
          .attr("cx", (d) => {
            const proj = projection([Number(d.lon), Number(d.lat)]);
            return proj ? proj[0] : 0;
          })
          .attr("cy", (d) => {
            const proj = projection([Number(d.lon), Number(d.lat)]);
            return proj ? proj[1] : 0;
          });

        // Reset zoom , uncomment if needed
        // svg.call(zoom.transform as any, d3.zoomIdentity);
      };
      window.addEventListener("resize", resizeMap);

      // Cleanup function
      return () => {
        window.removeEventListener("resize", resizeMap);
        d3.select(koreaMapRef.current).select("svg").remove();
      };
    } catch (err) {
      console.error("Error rendering map:", err);
      setError(
        "An error occurred while rendering the map. Please try again later.",
      );
    }
  }, [data]);

  return (
    <div className="col-12 col-lg-4">
      <div className="card custom-card">
        <CardHeader
          title="지역별 요청분포"
          tooltipId="regionInfo"
          tooltipContent="선택기간동안 지역별
              <br />
              요청분포도입니다."
          isHovered={isHovered}
          setIsHovered={() => setIsHovered((prev) => !prev)}
          selectedDays={selectedDays}
          onDaysSelection={onDaysSelection}
          dayOptions={[30, 60, 90]}
        />
        <div className="card-body pt-0">
          <div style={{ minHeight: "25rem" }}>
            {error ? (
              <div className="alert alert-danger">{error}</div>
            ) : (
              <>
                <div
                  ref={koreaMapRef}
                  style={{ width: "100%", height: "25rem" }}
                ></div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestByRegion;
