import * as Api from "@/api";
import ConfirmModalTypes from "@/components/Common/ConfirmModalTypes";
import FormButtonsTypes from "@/components/Common/FormButtonsTypes";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from "@/helpers/constants";
import { useLocalizedMessage } from "@/helpers/hooks";
import { AvForm } from "availity-reactstrap-validation";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import PageContainer from "../../components/Common/PageContainer";
import FormInputTypes from "../../components/Profile/FormInputTypes";
import Selector from "../../components/Profile/Selector";
import { OptionProps } from "../AppEquipment";
import CustomCkEditor from "../EmailTemplate/components/CustomCkEditor";
import RadioInput from "./components/RadioInput";

const BREADCRUMB_ITEMS = [
  { title: "Send Email", link: "#" },
  { title: "Add", link: "#" },
];

const SendEmail = () => {
  const navigate = useNavigate();
  const getLocalizedMessage = useLocalizedMessage();

  const [emailTempOptions, setEmailTempOptions] = useState<OptionProps[]>([]);
  const [emailContent, setEmailContent] = useState<
    Api.Response.EmailTemplateMain[]
  >([]);
  const [email, setEmail] = useState<Api.Request.SendEmail>({
    title: "",
    content: "",
    contentEn: "",
    targetType: 1,
    targetEmails: "",
  });
  const [selectedTemplateIdx, setSelectedTemplateIdx] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState("send");
  const [isEditorFocused, setIsEditorFocused] = useState(false);

  useEffect(() => {
    const getAllEmailTemplates = async () => {
      const response = await Api.EmailTemplate.getAll();
      setEmailContent(response.data);
      const templates = response.data.map((item) => ({
        value: item.tempIdx,
        label: item.title,
      }));
      setEmailTempOptions(templates);

      if (templates.length) {
        const firstTemplate = response.data[0];
        setEmail((prev) => ({
          ...prev,
          content: firstTemplate.content,
          contentEn: firstTemplate.contentEn,
        }));
        setSelectedTemplateIdx(firstTemplate.tempIdx.toString());
      }
    };
    getAllEmailTemplates();
  }, []);

  const openModal = (actionType: string) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleChange = (e: {
    target: {
      name: string;
      value: string;
      type: string;
    };
  }) => {
    const { name, value, type } = e.target;
    const newValue = type === "radio" ? parseInt(value, 10) : value;
    if (name === "targetType" && newValue === 0) {
      setEmail((prevEmail) => ({
        ...prevEmail,
        [name]: newValue,
        targetEmails: "",
      }));
    } else if (name === "tempIdx") {
      const selectedTemplate = emailContent.find(
        (template) => template.tempIdx === parseInt(value, 10),
      );
      if (selectedTemplate) {
        setEmail((prevEmail) => ({
          ...prevEmail,
          content: selectedTemplate.content || "",
          contentEn: selectedTemplate.contentEn || "",
        }));
        setSelectedTemplateIdx(value);
      }
    } else {
      setEmail((prevEmail) => ({ ...prevEmail, [name]: newValue }));
    }
  };

  const handleSendEmail = async () => {
    if (!email.title || (email.targetType === 1 && !email.targetEmails)) {
      toast.error("(*) 필드 모두 입력해주세요", {
        autoClose: 3000,
      });
      setIsOpen(false);
      return;
    }

    setIsLoading(true);
    try {
      const response = await Api.Emailing.send(email);
      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        navigate("/email-history");
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
      setIsOpen(false);
    }
  };
  const handleCancel = () => {
    navigate(`/email-history`);
  };

  return (
    <>
      <PageContainer breadcrumbItems={BREADCRUMB_ITEMS} title="Email Template">
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <h4 className="card-title">이메일 전송내용을 입력해주세요</h4>
                <AvForm className="mt-4">
                  <Row className="mb-3">
                    <Selector
                      label="템플릿"
                      id="tempIdx"
                      options={emailTempOptions}
                      value={selectedTemplateIdx}
                      onChange={handleChange}
                      isRequired
                    />
                  </Row>
                  <Row>
                    <FormInputTypes
                      id="title"
                      label="이메일제목"
                      placeholder="이메일제목을 입력해주세요"
                      type="text"
                      value={email.title || ""}
                      onChange={handleChange}
                      initialErrorMessage="이메일제목을 입력해주세요"
                      validate={{
                        required: {
                          value: true,
                        },
                      }}
                      fullWidthLabel={false}
                      isRequired
                    />
                  </Row>
                  <Row className="mb-3">
                    <Col xl="2" md="2">
                      <Label className="col-md-2 col-form-label w-100">
                        전송대상(<span className="text-danger">*</span>)
                      </Label>
                    </Col>
                    <Col
                      xl="10"
                      md="10"
                      className="d-flex align-items-center gap-2"
                    >
                      <RadioInput
                        value={0}
                        checked={email.targetType === 0}
                        id="radio1"
                        label="전체회원"
                        onHandleChange={handleChange}
                      />
                      <RadioInput
                        value={1}
                        checked={email.targetType === 1}
                        id="radio2"
                        label="메일입력"
                        onHandleChange={handleChange}
                      />
                    </Col>
                  </Row>
                  {email.targetType === 1 && (
                    <Row className="mb-3">
                      <Col xl="2" md="2"></Col>
                      <Col xl="10" md="10">
                        <div>
                          <textarea
                            name="targetEmails"
                            id="targetEmails"
                            className="form-control"
                            rows={5}
                            value={email.targetEmails || ""}
                            onChange={handleChange}
                          ></textarea>
                          <small>(,)단위로 메일주소를 입력해주세요.</small>
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row className="mb-3">
                    <Label
                      htmlFor="content"
                      className="col-md-2 col-form-label"
                    >
                      내용(한글) (<span className="text-danger">*</span>)
                    </Label>
                    <Col md={10}>
                      <CustomCkEditor
                        id="content"
                        data={email.content || ""}
                        isFocused={isEditorFocused}
                        handleFocus={() => setIsEditorFocused(true)}
                        handleBlur={() => setIsEditorFocused(false)}
                        onChange={(content: string) =>
                          setEmail((prev) => ({ ...prev, content }))
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label
                      htmlFor="contentEn"
                      className="col-md-2 col-form-label"
                    >
                      내용(영문) (<span className="text-danger">*</span>)
                    </Label>
                    <Col md={10}>
                      <CustomCkEditor
                        id="contentEn"
                        data={email.contentEn || ""}
                        isFocused={isEditorFocused}
                        handleFocus={() => setIsEditorFocused(true)}
                        handleBlur={() => setIsEditorFocused(false)}
                        onChange={(contentEn: string) =>
                          setEmail((prev) => ({ ...prev, contentEn }))
                        }
                      />
                    </Col>
                  </Row>
                  <FormButtonsTypes
                    openModal={openModal}
                    handleCancel={handleCancel}
                    hideDeleteButton
                    isSendingEmail
                  />
                  <ConfirmModalTypes
                    isLoading={isLoading}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    action={action}
                    handleSave={handleSendEmail}
                  />
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </PageContainer>
    </>
  );
};

export default SendEmail;
