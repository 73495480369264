import { useEffect, useRef, useState } from "react";
import * as Api from "@/api";
import CardHeader from "./components/CardHeader";

type TopRequestModelProps = {
  data: Api.Response.TopRequestModel;
  selectedDays: number;
  onDaysSelection: (days: number) => void;
};

const TopRequestModel = ({
  data,
  selectedDays,
  onDaysSelection,
}: TopRequestModelProps) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!chartRef.current) return;
    const chartOptions = {
      series: [
        {
          data: data.cntList,
        },
      ],
      chart: {
        height: 290,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors: [
        "#5c67f7",
        "#e354d4",
        "#ff8e6f",
        "#0ca3e7",
        "#fe5454",
        "#0cd7b1",
        "#7b76fe",
        "#fe7c58",
        "#10b818",
        "#fff96c",
      ],
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: false,
          columnWidth: "35%",
          borderRadius: "4",
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      grid: {
        show: true,
        borderColor: "#f1f1f1",
        strokeDashArray: 3,
      },
      xaxis: {
        categories: data.classCodeList,
        labels: {
          style: {
            colors: [
              "#5c67f7",
              "#e354d4",
              "#ff8e6f",
              "#0ca3e7",
              "#fe5454",
              "#0cd7b1",
              "#7b76fe",
              "#fe7c58",
            ],
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            colors: "#8c9097",
            fontSize: "11px",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-label",
          },
        },
      },
    };

    // This is "modelChart"
    const chart = new ApexCharts(chartRef.current, chartOptions);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data]);

  return (
    <div className="col-12 col-lg-4">
      <div className="card custom-card">
        <CardHeader
          title="TOP10 요청모델"
          tooltipId="reqModelInfo"
          tooltipContent="선택기간동안 사용된 상위10개 모델 <br />
              입니다."
          isHovered={isHovered}
          setIsHovered={() => setIsHovered((prev) => !prev)}
          selectedDays={selectedDays}
          onDaysSelection={onDaysSelection}
          dayOptions={[30, 60, 90]}
        />
        <div className="card-body">
          <div style={{ minHeight: "20.625rem" }}>
            <div ref={chartRef}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopRequestModel;
