/* eslint-disable react/no-unknown-property */
// switch property used in _switch.scss input[switch]
import React from "react";
import { Col, Label } from "reactstrap";

const Checkbox = ({
  label,
  id,
  checked,
  onChange,
  disabled = false,
  extraClass = "",
  style = {},
  colMd = 10,
  fullWidthLabel = true,
}) => {
  return (
    <>
      <Label
        htmlFor={id}
        className={`col-md-2 col-form-label ${fullWidthLabel ? "w-100" : ""}`}
      >
        {label}
      </Label>

      <Col md={colMd}>
        <div className={`d-flex align-center ${extraClass}`} style={style}>
          <input
            type="checkbox"
            id={id}
            name={id}
            checked={checked}
            onChange={onChange}
            switch="none"
            disabled={disabled}
          />
          <label
            htmlFor={id}
            data-on-label="On"
            data-off-label="Off"
            style={disabled ? { cursor: "initial" } : {}}
          />
        </div>
      </Col>
    </>
  );
};

export default Checkbox;
